import ApiConfig from "../../../../config/api-config";
import { $apiClient } from "../../../../utils/apiClient";

export function getData() {
    return $apiClient.get(ApiConfig.FILTERS.CURRENCY);
}

export function setCurrentCurrency(data) {
    localStorage.setItem('currencyId', data);
}
