import { createSelector } from "@reduxjs/toolkit";
import { initialState } from "./state";

const selectDomain = (state) => state.location || initialState;

export const selectLocationData = createSelector(
    [selectDomain],
    (locationState) => locationState.data
);

export const selectLocationLoading = createSelector(
    [selectDomain],
    (locationState) => locationState.fetch.loading
);

export const selectLocationDone = createSelector(
    [selectDomain],
    (locationState) => locationState.fetch.done
);

export const selectLocationError = createSelector(
    [selectDomain],
    (locationState) => locationState.fetch.error
);
