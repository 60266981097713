import { createSelector } from "@reduxjs/toolkit";
import { initialState } from "./state";

const selectDomain = (state) => state.currency || initialState;

export const selectCurrencyData = createSelector(
    [selectDomain],
    (currencyState) => currencyState.data
);

export const selectCurrencyLoading = createSelector(
    [selectDomain],
    (currencyState) => currencyState.fetch.loading
);

export const selectCurrencyDone = createSelector(
    [selectDomain],
    (currencyState) => currencyState.fetch.done
);

export const selectCurrencyError = createSelector(
    [selectDomain],
    (currencyState) => currencyState.fetch.error
);

export const selectCurrentCurrencyId = createSelector(
  [selectDomain],
  (currencyState) => currencyState.currencyId
);
