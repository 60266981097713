import * as React from "react"

const Cross = () => (
    <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
    >
        <g clipPath="url(#a)">
            <path d="M20 0H0v20h20V0Z" fill="#fff" fillOpacity={0.01} />
            <path
                d="m5.833 5.836 8.334 8.333M5.833 14.17l8.334-8.334"
                stroke="#BAD0D3"
                strokeWidth={1.667}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </g>
        <defs>
            <clipPath id="a">
                <path fill="#fff" d="M0 0h20v20H0z" />
            </clipPath>
        </defs>
    </svg>
)

export default Cross;
