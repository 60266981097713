import React from "react";

// styles
import "./index.scss"

const PrivacyPolicy = () => {
  return (
    <section className="privacy-policy">
      <div className="app-container">
        <div className="privacy-policy__container">
          <h1 className="privacy-policy__title">Privacy Policy for Perfect Baby Fit Website</h1>
          <p className="privacy-policy__text">
            At Perfect Baby Fit, we are committed to protecting your privacy. This Privacy Policy explains how
            we collect, use, and share your personal information when you use our website to search for baby
            clothes in relevant sizes based on your baby's age, gender, weight, and height. By using our
            website, you agree to the terms of this Privacy Policy.
          </p>
          <div>
            <h4 className="privacy-policy__subtitle">Information We Collect</h4>
            <p className="privacy-policy__text">
              When you use Perfect Baby Fit to search for baby clothes in relevant sizes, we may collect the
              following types of personal information:
            </p>
            <ul className="privacy-policy__list">
              <li className="privacy-policy__item">
                Information you provide to us: We may collect information you provide to us, such as your
                name,
                email address, and other contact information. We may also collect information about your
                baby's
                age, gender, weight, and height to provide you with more accurate size recommendations.
              </li>
              <li className="privacy-policy__item">
                Information we collect automatically: We may collect certain information automatically when
                you
                use our website, such as your IP address, browser type, operating system, and other usage
                information. We may also use cookies, web beacons, and similar technologies to collect
                information about your interactions with our website.
              </li>
            </ul>
          </div>
          <div>
            <h4 className="privacy-policy__subtitle">Use of Information</h4>
            <p className="privacy-policy__text">
              We use the information we collect to provide and improve our website and services, including:
            </p>
            <ul className="privacy-policy__list">
              <li className="privacy-policy__item">
                To personalize your experience on our website and show you baby clothes in relevant sizes
                based
                on your baby's age, gender, weight, and height.
              </li>
              <li className="privacy-policy__item">
                To communicate with you about our services, including responding to your inquiries and
                providing
                customer support.
              </li>
              <li className="privacy-policy__item">
                To analyze and improve our website and services, including measuring the effectiveness of
                our
                marketing campaigns and optimizing our website for different devices and browsers.
              </li>
              <li className="privacy-policy__item">
                To comply with legal obligations, such as responding to legal requests or preventing fraud
                or illegal activity.
              </li>
            </ul>
          </div>
          <div>
            <h4 className="privacy-policy__subtitle">Sharing of Information</h4>
            <p className="privacy-policy__text">
              We may share your personal information with third parties in the following circumstances:
            </p>
            <ul className="privacy-policy__list">
              <li className="privacy-policy__item">
                With your consent: We may share your personal information with third parties if you have
                given us consent to do so.
              </li>
              <li className="privacy-policy__item">
                With service providers: We may share your personal information with service providers who
                help us operate our website and provide our services, such as hosting providers and email
                service providers.
              </li>
              <li className="privacy-policy__item">
                With affiliates: We may share your personal information with our affiliates, such as parent
                or subsidiary companies, to provide you with information about related products or services.
              </li>
              <li className="privacy-policy__item">
                For legal reasons: We may share your personal information with law enforcement authorities
                or other third parties if we believe it is necessary to comply with legal obligations or to
                protect our rights or the rights of others.
              </li>
            </ul>
          </div>
          <div>
            <h4 className="privacy-policy__subtitle">Security of Information</h4>
            <p className="privacy-policy__text">
              We take reasonable measures to protect your personal information from unauthorized access, use,
              or disclosure. However, no security measures are perfect or impenetrable, and we cannot
              guarantee the security of your personal information.
            </p>
          </div>
          <div>
            <h4 className="privacy-policy__subtitle">Retention of Information</h4>
            <p className="privacy-policy__text">
              We will retain your personal information for as long as necessary to provide our services and
              fulfill the purposes described in this Privacy Policy, unless a longer retention period is
              required or permitted by law.
            </p>
          </div>
          <div>
            <h4 className="privacy-policy__subtitle">Your Rights</h4>
            <p className="privacy-policy__text">
              You have certain rights with respect to your personal information, including:
            </p>
            <ul className="privacy-policy__list">
              <li className="privacy-policy__item">
                The right to access your personal information and receive a copy of it.
              </li>
              <li className="privacy-policy__item">
                The right to correct any inaccurate or incomplete personal information we have about you.
              </li>
              <li className="privacy-policy__item">
                The right to object to the processing of your personal information in certain circumstances.
              </li>
              <li className="privacy-policy__item">
                The right to request that we delete your personal information under certain circumstances.
              </li>
            </ul>
            <p className="privacy-policy__text">
              To exercise your rights, please contact us using the information provided below.
            </p>
          </div>
          <div>
            <h4 className="privacy-policy__subtitle">Updates to Privacy Policy</h4>
            <p className="privacy-policy__text">
              We may update this Privacy Policy from time to time. If we make material changes to this Privacy
              Policy, we will notify you by posting a notice on our website or by other means.
            </p>
          </div>
          <div>
            <h4 className="privacy-policy__subtitle">Contact Us</h4>
            <p className="privacy-policy__text">
              If you have any questions or concerns about our Privacy Policy, please contact us at
              <a className="privacy-policy__link" href="mailto:mpj2310@gmail.com"> email</a>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default PrivacyPolicy;
