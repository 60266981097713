import React from "react";
import {useTranslation} from "react-i18next";

// styles
import "./index.scss"

// data
import mainImage from "../../../assets/images/main.png";

const MainContent = () => {
  const {t} = useTranslation();
  return (
    <section className="main-content">
      <div className="app-container">
        <div className="main-content__info">
          <div className="main-content__crop">
            <img src={mainImage} alt="clothes" className="main-content__image"/>
          </div>
          <div className="main-content__box">
            <h2 className="main-content__title">{t('mainContent.infoTitle')}</h2>
            <p className="main-content__text">{t('mainContent.infoText')}</p>
          </div>
        </div>
        <h2 className="main-content__title">{t('mainContent.forWhom.title')}</h2>
        <div className="main-content__columns">
          <p className="main-content__text">
            {t('mainContent.forWhom.text1')}
          </p>
          <p className="main-content__text">
            {t('mainContent.forWhom.text2')}
          </p>
          <p className="main-content__text">
            {t('mainContent.forWhom.text3')}
          </p>
        </div>
      </div>
    </section>
  );
}

export default MainContent;
