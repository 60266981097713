import { compose, createStore, combineReducers } from 'redux';
import { applyMiddleware } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import rootReducer from './rootReduсer';
import { watchSagas } from './watchSagas';

const reduxSagaMonitorsOptions = {};

const sagaMiddleware = createSagaMiddleware(reduxSagaMonitorsOptions);

const middlewares = [sagaMiddleware];

const reducers = combineReducers(rootReducer);

const configureAppStore = () => {
    const store = createStore(
        reducers,
        {},
        compose(
            applyMiddleware(...middlewares),
            window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
                ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__()
                : (f) => f,
        ),
    );

    sagaMiddleware.run(watchSagas);

    return store;
};

const store = configureAppStore();

export default store;
