import { call, put } from "redux-saga/effects";
import { actions as colorsActions } from "./slice";
import * as colorsServices from "./services";

/**
 * Fetch parameters request/response handler
 */
export function* fetchColorsSaga() {
    try {
        const { data } = yield call(colorsServices.getData);

        yield put(colorsActions.fetchDataSuccess(data));
    } catch (err) {
        yield put(colorsActions.fetchDataFailed(err));
    } finally {
        yield put(colorsActions.fetchDataFulfilled())
    }
}
