import React, { useState } from "react";
import { useTranslation } from "react-i18next";

// components
import FilterTitle from "../filterTitle";
import SlideToggle from "react-slide-toggle";

// styles
import "./index.scss"

const Filter = ({ items, title, data, filterKey, onClick }) => {
    const { t } = useTranslation();
    const [showAll, setShowAll] = useState(false);
    const [filteredItems, setFilteredItems] = useState(items.slice(0, 6));

    const onShowAllHandler = () => {
        setFilteredItems(!showAll ? items : items.slice(0, 6));
        setShowAll(!showAll);
    }

    const onCheckboxHandler = (event) => {
        let name = event.target.name;
        let dataArray = data.length ? data.split(',') : [];

        let inArrayIndex = dataArray.findIndex(item => item === name)

        if (dataArray.length && inArrayIndex >= 0) {
            dataArray.splice(inArrayIndex, 1)
        } else {
            dataArray.push(name)
        }
        onClick({ [filterKey]: dataArray.join(',') })
    }

    return (
        <div className="filter">
            <SlideToggle collapsed>
                {({ toggle, setCollapsibleElement, toggleState }) => (
                    <>
                        <FilterTitle title={title} toggle={toggle} toggleState={toggleState}/>
                        <div ref={setCollapsibleElement}>
                            {filteredItems.length > 0 ? <div className="filter__list">
                                {filteredItems.map((item, index) => (
                                    <div className="app-checkbox" key={index}>
                                        <input type="checkbox"
                                               id={item.name}
                                               name={item.id}
                                               onChange={(e) => onCheckboxHandler(e)}
                                               checked={!!(data.split(',').find(id => Number(id) === item.id))}
                                               className="app-checkbox__input"/>
                                        <label htmlFor={item.name} className="app-checkbox__label">{item.name}</label>
                                        {/*<p>{item.quantity}</p>*/}
                                    </div>
                                ))
                                }
                            </div> : (<div>Not Found</div>)}

                            {items.length > 6 &&
                                <div onClick={onShowAllHandler}
                                     className="filter__show-all">
                                    {!showAll ? `${t('filter.showAll')}` : `${t('filter.showLess')}`}
                                </div>
                            }

                        </div>
                    </>
                )}
            </SlideToggle>
        </div>
    );
}

export default Filter;
