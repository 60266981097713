import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";

// store
import {selectMainFilteredError} from "../../../store/modules/mainFilteredProducts/selectors";

// components
import Arrow from "../../../iconsComponents/Arrow";
import SizeForm from "../../../components/SizeForm";
import ArrowUp from "../../../iconsComponents/ArrowUp";

// styles
import "./index.scss"

const EnteredSize = ({enteredSize, onClick}) => {
  const [open, setOpen] = useState(false)
  const {t} = useTranslation();
  const mainFilteredError = useSelector(selectMainFilteredError)

  let {gender, birthDate, height, weight} = enteredSize;


  useEffect(() => {
    if (mainFilteredError?.response.data.message) {
      setOpen(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainFilteredError]);

  const handleOpenForm = () => {
    setOpen(!open)
  }

  return (
    <section className="entered-size">
      <div className="entered-size__head">
        <div className="entered-size__wrapper">
          <div>
            <div className="entered-size__name">{t('form.gender')}</div>
            <div className="entered-size__value">{gender ? gender : '---'}</div>
          </div>
          <div>
            <div className="entered-size__name">{t('form.dateOfBirth')}</div>
            <div className="entered-size__value">{birthDate ? birthDate : '---'}</div>
          </div>
          <div>
            <div className="entered-size__name">{t('form.height')}</div>
            <div className="entered-size__value">{height ? `${height}cm` : '---'}</div>
          </div>
          <div>
            <div className="entered-size__name">{t('form.weight')}</div>
            <div className="entered-size__value">{weight ? `${weight}kg` : '---'}</div>
          </div>
        </div>
        <div onClick={handleOpenForm} className="entered-size__arrow ">
          {!open && <Arrow width={14} height={8}/>}
          {open && <ArrowUp width={14} height={8}/>}
        </div>
      </div>
      <div className={open ? 'entered-size__form' : 'entered-size__form-closed'}
      >
        <SizeForm customStyle={{backgroundColor: '#F7F7F7'}} enteredSize={enteredSize} open={open}
                  isCatalog={false}
               onClick={onClick}/>
      </div>
    </section>
  );
}
export default EnteredSize;
