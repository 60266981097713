import { createSelector } from "@reduxjs/toolkit";
import { initialState } from "./state";

const selectDomain = (state) => state.language || initialState;

export const selectLanguageData = createSelector(
    [selectDomain],
    (languageState) => languageState.data
);

export const selectLanguageLoading = createSelector(
    [selectDomain],
    (languageState) => languageState.fetch.loading
);

export const selectLanguageDone = createSelector(
    [selectDomain],
    (languageState) => languageState.fetch.done
);

export const selectLanguageError = createSelector(
    [selectDomain],
    (languageState) => languageState.fetch.error
);
