import React, {useEffect, useState} from "react";

// components
import Banner from "./Banner";
import MainContent from "./MainContent";
import ModalDelivery from "./ModalDelivery";

const Main = () => {
  const [isOpen, setIsOpen] = useState(false);

  function toggleModal() {
    setIsOpen(false);
  }

  useEffect(() => {
    setTimeout(() => {
      let pop_status = localStorage.getItem('pop_status');
      if (!pop_status) {
        setIsOpen(!isOpen)
        localStorage.setItem('pop_status', 1);
      }
    }, 1000)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <section className="main">
      <Banner/>
      <MainContent/>
      <ModalDelivery toggleModal={toggleModal} isOpen={isOpen}/>
    </section>
  );
}
export default Main;
