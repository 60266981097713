const ApiConfig = {
    BASE_URL: process.env.REACT_APP_API_URL,
    SEARCH: {
        SEARCH: "search/search",
        GET_SIZE: "search/get-size-v2",
        NOTIFICATION: "search/product-notification",
    },
    FILTERS: {
        STOCK: "filters/stock",
        SHIPPING: "filters/shipping",
        MATERIALS: "filters/materials",
        CATEGORIES: "filters/get-clothes-categories",
        COMPANIES: "filters/get-all-companies",
        CONDITIONS: "filters/conditions",
        COLORS: "filters/colors",
        LOCATION: "filters/location",
        CURRENCY: "filters/currency",
        LANGUAGE: "filters/language",
    },
    SORTING: {
        SORTING: "sortings/",
    }
};

export default ApiConfig;
