import { withStyles } from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';

const CustomSlider = withStyles({
    thumb: {
        "&.MuiSlider-thumb-overflowVisible": {
            boxShadow: "none",
        },
    },
    track: {
        boxShadow: 'none',
    },
    rail: {
        boxShadow: 'none',
    },
})(Slider);

export default CustomSlider;