import React from "react";

const Arrow = ({height, width}) => {
    return (
        <svg width={width} height={height} viewBox="0 0 15 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.5 1L7.5 7L1.5 1" stroke="#53565A" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
         </svg>
    );
}

export default Arrow;