import { call, put } from "redux-saga/effects";
import { actions as stockActions } from "./slice";
import * as stockServices from "./services";

/**
 * Fetch parameters request/response handler
 */
export function* fetchStockSaga() {
    try {
        const {data} = yield call(stockServices.getData);

        yield put(stockActions.fetchDataSuccess(data));
    } catch (err) {
        yield put(stockActions.fetchDataFailed(err));
    } finally {
        yield put(stockActions.fetchDataFulfilled())
    }
}