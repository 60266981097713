// core
import React, {useEffect, useRef, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";

// utils
import {objectToString} from "../../utils";
import {FILTERS_TEMPLATE} from "../../utils/filters";

// store

import {selectMainFilteredError, selectMainFilteredLoading, selectMainFilteredData} from "../../store/modules/mainFilteredProducts/selectors";
import {actions as mainFilteredProductsActions} from "../../store/modules/mainFilteredProducts/slice";

// components
import AdvancedSearch from "../../iconsComponents/AdvancedSearch";
import SizeForm from "../SizeForm";
import CatalogFilter from "../CatalogFilter";

// style
import "./index.scss"

const SearchForm = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const ref = useRef(null);
  const dispatch = useDispatch();
  const mainFilteredError = useSelector(selectMainFilteredError)
  const mainFilteredLoading = useSelector(selectMainFilteredLoading)
  const mainFilteredData = useSelector(selectMainFilteredData)


  const [menuOpen, setMenuOpen] = useState(false);
  const [enteredSize, setEnteredSize] = useState(null)
  const [advancedFilters, setAdvancedFilters] = useState(null);
  const [filterParams, setFilterParams] = useState(null);

  useEffect(() => {
    let enteredSizeKeys = ['gender', 'birthDate', 'height', 'weight'];
    let enteredSizeObject = enteredSizeKeys.reduce((acc, key) => {
      let value = FILTERS_TEMPLATE[key]
      return {...acc, [key]: value}
    }, {})
    setEnteredSize(enteredSizeObject)

    let advancedCurrentFilters = Object.keys(FILTERS_TEMPLATE).reduce((acc, key) => {
      let advancedCurrentFilter = enteredSizeKeys.indexOf(key) < 0 ? {[key]: FILTERS_TEMPLATE[key]} : {};
      return {...acc, ...advancedCurrentFilter};
    }, {});
    setAdvancedFilters(advancedCurrentFilters);
  }, [])


  useEffect(() => {
    if (!mainFilteredError?.response.data.message && mainFilteredData && !mainFilteredLoading && filterParams) {
      navigate({
        pathname: '/catalog',
        search: `?${objectToString(Object.assign(filterParams, advancedFilters ? advancedFilters : {}))}`
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainFilteredError, mainFilteredData, mainFilteredLoading]);

  const handleAdvancedClick = () => {
    setMenuOpen(!menuOpen);
  }

  const onFilterHandler = (filterParams) => {
    setAdvancedFilters({...advancedFilters, ...filterParams});
  }

  const onSizeHandler = (filterParams) => {
    Object.keys(filterParams).forEach(key => {
      if (!filterParams[key]) {
        delete filterParams[key]
      }
    })
    setFilterParams(filterParams)
    dispatch(mainFilteredProductsActions.fetchDataTrigger(filterParams));
  }


  return (
    <div className="searchForm" ref={ref}>
      {enteredSize && <SizeForm customStyle={{backgroundColor: '#fff'}}
                                isCatalog={false}
                             enteredSize={enteredSize}
                             onClick={onSizeHandler}/>
      }
      <div className="advancedSearch">
        <div onClick={handleAdvancedClick}>
          <span className="advancedSearch-icon">{t('form.advanced')}</span>
          <AdvancedSearch/>
        </div>
      </div>
      {menuOpen && (
        <CatalogFilter
          style={{
            padding: '32px',
            position: "fixed",
            zIndex: '1000',
            overflowY: 'auto',
            margin: '0',
            width: '260px',
            height: '100%',
            right: '0',
            top: '0',
          }}
          width={window.innerWidth >= 10000}
          filters={advancedFilters}
          onClick={onFilterHandler}
          setMenuOpen={setMenuOpen}
        />
      )}
    </div>
  );
}

export default SearchForm;
