import React from "react";

// styles
import "./index.scss"

const CookiePolicy = () => {
  return (
    <section className="cookie-policy">
      <div className="app-container">
        <h1 className="cookie-policy__title">Cookie Policy</h1>
        <p className="cookie-policy__text">
          This Cookie Policy explains how Perfect Baby Fit ("we", "us", or "our") uses cookies and similar
          technologies to collect information about your use of our website. By using our website, you consent
          to our use of cookies in accordance with this Cookie Policy.
        </p>
        <div>
          <h4 className="cookie-policy__subtitle">What are cookies?</h4>
          <p className="cookie-policy__text">
            Cookies are small data files that are placed on your device when you visit our website. They
            allow our website to recognize your device and store information about your preferences or past
            actions.
          </p>
        </div>
        <div>
          <h4 className="cookie-policy__subtitle">What types of cookies do we use?</h4>
          <p className="cookie-policy__text">
            We use both session cookies and persistent cookies on our website. Session cookies are temporary
            cookies that are deleted when you close your browser, while persistent cookies remain on your
            device until they expire or you delete them.
          </p>
          <p className="cookie-policy__text">
            We use the following types of cookies on our website:
          </p>
          <ul className="cookie-policy__list">
            <li className="cookie-policy__item">
              Essential cookies: These cookies are necessary for our website to function properly and
              cannot be switched off in our systems. They are usually only set in response to actions made
              by you, such as setting your privacy preferences, logging in, or filling in forms.
            </li>
            <li className="cookie-policy__item">
              Performance cookies: These cookies allow us to count visits and traffic sources so we can
              measure and improve the performance of our website. They help us to know which pages are the
              most and least popular and see how visitors move around the site.
            </li>
            <li className="cookie-policy__item">
              Functionality cookies: These cookies enable the website to provide enhanced functionality
              and personalization, such as remembering your preferences and choices
            </li>
            <li className="cookie-policy__item">
              Targeting cookies: These cookies may be set through our site by our advertising partners.
              They may be used to build a profile of your interests and show you relevant advertisements
              on other sites.
            </li>
          </ul>
        </div>
        <div>
          <h4 className="cookie-policy__subtitle">How to manage cookies?</h4>
          <p className="cookie-policy__text">
            You can control cookies through your browser settings. However, please note that disabling
            cookies may affect the functionality of our website and limit your ability to use certain
            features.
          </p>
        </div>
        <div>
          <h4 className="cookie-policy__subtitle">Changes to this Cookie Policy</h4>
          <p className="cookie-policy__text">
            We may update this Cookie Policy from time to time. Any changes will be posted on this page, and
            the revised policy will take effect immediately upon posting.
          </p>
        </div>
        <div>
          <h4 className="cookie-policy__subtitle">Contact Us</h4>
          <p className="cookie-policy__text">
            If you have any questions about this Cookie Policy, please contact us at
            <a className="cookie-policy__link" href="mailto:mpj2310@gmail.com"> email</a>
          </p>
        </div>
      </div>
    </section>
  );
}

export default CookiePolicy;
