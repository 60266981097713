import React from "react";
import ClickAwayListener from 'react-click-away-listener';

// components
import CloseIcon from "../../../components/icons/CloseIcon";

// styles
import "./index.scss";

// data
import country from "../../../assets/images/delivery.png";

const ModalDelivery = ({toggleModal, isOpen}) => {

  return (
    <div className={isOpen ? "modal-delivery" : "modal-delivery--closed"}>
      <ClickAwayListener onClickAway={toggleModal}>
        <div className="modal-delivery__container">
          <div className="modal-delivery__left">
            <img src={country} alt="country"/>
          </div>
          <div className="modal-delivery__right">
            <div className="modal-delivery__close" onClick={toggleModal}>
              <CloseIcon/>
            </div>
            <h3 className="modal-delivery__title">
              Delivery to Israel
            </h3>
            <div className="modal-delivery__text">
              With us it is easy to shop from Israel
            </div>
            <div className="modal-delivery__text">
              You can set all prices in shekels
            </div>
            {/*<div className="modal-text">*/}
            {/*    לא שולח לישראל? לחץ כאן*/}
            {/*</div>*/}
            <div className="modal-delivery__close-button" onClick={toggleModal}>
              Continue shopping
            </div>
          </div>
        </div>
      </ClickAwayListener>
    </div>
  );
};

export default ModalDelivery;
