 import React, { useState } from "react";
import * as yup from "yup";
import { useDispatch } from "react-redux";

import CloseIcon from "../icons/CloseIcon";
import { actions as notificationActions } from "../../store/modules/notifications/slice";
// import { selectShippingData } from "../../store/modules/filters/shipping/selectors";

import "./modalForStock.scss";
import { toast } from "react-toastify";

const ModalForStock = ({ toggleModal, size, link }) => {
    const dispatch = useDispatch();
    const [newEmail, setNewEmail] = useState()
    const [data, setData] = useState({
        email: "",
        productUrl: link,
        size: size,
        arrivalsNotification: false
    });
    const [formError, setFormError] = useState();

    let schema = yup.object().shape({
        email: yup.string().email().required("Enter an email address"),
    });

    const handleEmailChange = (event) => {
        setNewEmail(event.target.value)
    };

    const onHandleClick = () => {
        schema
            .validate({
                email: newEmail,
            })
            .then((valid) => {
                if (valid) {
                    console.log(newEmail)
                    data.email = newEmail;
                    dispatch(notificationActions.fetchDataTrigger(data))
                }
                toggleModal();
                toast.success("Thanks for your request. We will notify you when your size in stock.", {
                    position: toast.POSITION.TOP_RIGHT
                });
            })
            .catch((error) => {
                setFormError(error.errors);
            })
    };

    const handleCheckbox = (event) =>  {
        // data.arrivalsNotification = event.target.checked
        setData({...data, arrivalsNotification: event.target.checked})
    }

    return (
        <div className="modal-wrapper-stock">
            <div className="modal-wrapper-header">
                <h3 className="modal-wrapper-header-title">
                    Email me if back <br/>
                    in stock
                </h3>
                <div className="modal-close-stock" onClick={toggleModal}>
                    <CloseIcon/>
                </div>
            </div>
            <div className="modal-text">
                Receive an email notification when your size
                <span className="size"> {size}</span> is available for this product
            </div>
            <input
                className="modal-input"
                type="email"
                id="email"
                name="email"
                placeholder="Enter an email address"
                onChange={handleEmailChange}
            />
            <div className="input-error">{formError && formError}</div>
            <div className="modal-checkbox-wrapper">
                <input
                    type="checkbox"
                    id="modal"
                    name="modal"
                    className="modal-checkbox"
                    onChange={handleCheckbox}
                />
                <label htmlFor="modal" className="modal-label">
                    Let's email! Join us today for tailored new arrivals, exciting
                    launches and exclusive early sale access.
                </label>
            </div>
            <button className="modal-button" onClick={onHandleClick}>
                Send
            </button>
        </div>
    );
};

export default ModalForStock;
