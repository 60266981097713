import { createSelector } from "@reduxjs/toolkit";
import { initialState } from "./state";

const selectDomain = (state) => state.mainFiltered || initialState;

export const selectMainFilteredData = createSelector(
    [selectDomain],
    (mainFilteredState) => mainFilteredState.data
);

export const selectMainFilteredLoading = createSelector(
    [selectDomain],
    (mainFilteredState) => mainFilteredState.fetch.loading
);

export const selectMainFilteredDone = createSelector(
    [selectDomain],
    (mainFilteredState) => mainFilteredState.fetch.done
);

export const selectMainFilteredError = createSelector(
    [selectDomain],
    (mainFilteredState) => mainFilteredState.fetch.error
);
