import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
//components
import Cross from "../../iconsComponents/Cross";

// styles
import "./index.scss"

// data
import {FILTERS_KEYS} from "./data";

const ChooseFilter = ({filters, collection, onClick}) => {

  const {t} = useTranslation();
  const [details, setDetails] = useState([]);
  useEffect(() => {
    let newDetails = []

    Object.keys(FILTERS_KEYS).forEach(key => {

      if (key === 'priceFrom' || key === 'priceTo') {
        if (filters[key]) {
          let value = filters[key]
          newDetails.push({
            name: key === 'priceFrom' ? `Price (min:${value})` : `Price(max:${value})`,
            key: key,
            value: value
          })
        }
      } else if (filters[key].length) {
        newDetails = filters[key].split(',').reduce((acc, id) => {
          let collectionItem = collection[FILTERS_KEYS[key]].find(i => i.id === Number(id));
          return [...acc, {
            name: collectionItem ? collectionItem.name : `${key}-${id}`,
            key: key,
            value: id
          }]
        }, newDetails)

      }

    })

    setDetails(newDetails)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters])

  //
  const handleSelectedClick = ({key, name, value}) => {
    if (key === 'priceFrom' || key === 'priceTo') {
      onClick({[key]: 0})
    } else {
      let dataArray = filters[key].length ? filters[key].split(',') : [];
      let inArrayIndex = dataArray.findIndex(item => item === value)

      if (dataArray.length && inArrayIndex >= 0) {
        dataArray.splice(inArrayIndex, 1)
      }
      onClick({[key]: dataArray.join(',')})
    }
  }

  const clearAllFiltersHandler = () => {
    let resetDetails = Object.keys(FILTERS_KEYS).reduce((acc, key) => {
      return {...acc, [key]: (key === 'priceFrom' || key === 'priceTo') ? 0 : ''}
    }, {});

    onClick(resetDetails)
  }

  return (
    <div className="choose-filter">
      <h4 className="choose-filter__head">{t('filter.title.choose')}:</h4>
      {details.map((detail, index) =>
        (<div className="choose-filter__body" key={index}>
          <div className="choose-filter__title">{detail.name}</div>
          <div className="choose-filter__action" onClick={() => handleSelectedClick(detail)}>
            <Cross/>
          </div>
        </div>))}

      {details.length > 0 ? (
        <div className="choose-filter__clear"
             onClick={() => clearAllFiltersHandler()}>{t('filter.clearFilter')}</div>
      ) : <div className="choose-filter__clear">{t('filter.emptyFilters')}</div>}
    </div>
  );
}

export default ChooseFilter;
