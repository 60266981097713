import { createSelector } from "@reduxjs/toolkit";
import { initialState } from "./state";

const selectDomain = (state) => state.materials || initialState;

export const selectMaterialsData = createSelector(
    [selectDomain],
    (materialsState) => materialsState.data
);

export const selectMaterialsLoading = createSelector(
    [selectDomain],
    (materialsState) => materialsState.fetch.loading
);

export const selectMaterialsDone = createSelector(
    [selectDomain],
    (materialsState) => materialsState.fetch.done
);

export const selectMaterialsError = createSelector(
    [selectDomain],
    (materialsState) => materialsState.fetch.error
);
