import { createSelector } from "@reduxjs/toolkit";
import { initialState } from "./state";

const selectDomain = (state) => state.colors || initialState;

export const selectColorsData = createSelector(
    [selectDomain],
    (colorsState) => colorsState.data
);

export const selectColorsLoading = createSelector(
    [selectDomain],
    (colorsState) => colorsState.fetch.loading
);

export const selectColorsDone = createSelector(
    [selectDomain],
    (colorsState) => colorsState.fetch.done
);

export const selectColorsError = createSelector(
    [selectDomain],
    (colorsState) => colorsState.fetch.error
);
