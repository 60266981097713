import { call, put } from "redux-saga/effects";
import { actions as shippingActions } from "./slice";
import * as shippingServices from "./services";

/**
 * Fetch parameters request/response handler
 */
export function* fetchShippingSaga() {
    try {
        const {data} = yield call(shippingServices.getData);

        yield put(shippingActions.fetchDataSuccess(data));
    } catch (err) {
        yield put(shippingActions.fetchDataFailed(err));
    } finally {
        yield put(shippingActions.fetchDataFulfilled())
    }
}