// core
import React, { useEffect, useState } from "react";
import SlideToggle from "react-slide-toggle";
import { useTranslation } from "react-i18next";

// components
import FilterTitle from "../filterTitle";
import CustomSlider from "../../utils/CustomSlider";

// style
import "./index.scss"

const PriceFilter = ({ data, onClick }) => {
    const { t } = useTranslation();
    const [priceRange, setPriceRange] = useState([0, 10000]);

    useEffect(() => {
        setPriceRange([data.priceFrom, data.priceTo])
    }, [data])

    const onChangeHandler = (event, key) => {
        let value = Number(event.target.value)
        setPriceRange(key === 'priceFrom' ? [value, priceRange[1]] : [priceRange[0], value])
    }

    const rangeSelector = (event, range) => {
        setPriceRange(range)
    };

    const onClickHandler = () => {
        onClick({ priceFrom: priceRange[0], priceTo: priceRange[1], })
    }

    return (
        <div className="price-filter">
            <SlideToggle>
                {({ toggle, setCollapsibleElement, toggleState }) => (
                    <div>
                        <FilterTitle title={t('filter.title.price')} toggle={toggle} toggleState={toggleState}/>
                        <div ref={setCollapsibleElement}>
                            <div className="price-filter__range">
                                <input type="number"
                                       className="price-filter__input"
                                       value={priceRange[0]}
                                       onChange={(e) => onChangeHandler(e, 'priceFrom')}/>
                                <span>-</span>
                                <input type="number"
                                       className="price-filter__input"
                                       value={priceRange[1]}
                                       onChange={(e) => onChangeHandler(e, 'priceTo')}/>
                            </div>
                            <CustomSlider
                                value={priceRange}
                                onChange={rangeSelector}
                                valueLabelDisplay="off"
                                min={0}
                                max={1000}
                            />
                            <div className="price-filter__button"
                                 onClick={onClickHandler}>{t('filter.priceApply')}</div>
                        </div>
                    </div>
                )}
            </SlideToggle>
        </div>
    );
}

export default PriceFilter;
