import { call, put } from "redux-saga/effects";
import { actions as sortingActions } from "./slice";
import * as sortingServices from "./services";

/**
 * Fetch parameters request/response handler
 */
export function* fetchSortingSaga() {
    try {
        const { data } = yield call(sortingServices.getData);

        yield put(sortingActions.fetchDataSuccess(data));
    } catch (err) {
        yield put(sortingActions.fetchDataFailed(err));
    } finally {
        yield put(sortingActions.fetchDataFulfilled())
    }
}
