import * as React from "react"

const Loader = () => (
    <svg width={45} height={44} className="load-more" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M22.923 2C11.842 2 2.859 10.954 2.859 22s8.983 20 20.064 20 20.064-8.954 20.064-20"
            stroke="#53565A"
            strokeWidth={4}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
)

export default Loader;