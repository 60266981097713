import { call, put } from "redux-saga/effects";
import { actions as notificationActions } from "./slice";
import * as notificationServices from "./services";

/**
 * Fetch parameters request/response handler
 */
export function* fetchNotificationSaga({ payload }) {
    try {

        const { data } = yield call(notificationServices.postData, payload);

        yield put(notificationActions.fetchDataSuccess(data));
    } catch (err) {
        yield put(notificationActions.fetchDataFailed(err));
    } finally {
        yield put(notificationActions.fetchDataFulfilled())
    }
}
