import { createSelector } from "@reduxjs/toolkit";
import { initialState } from "./state";

const selectDomain = (state) => state.companies || initialState;

export const selectCompaniesData = createSelector(
    [selectDomain],
    (companiesState) => companiesState.data
);

export const selectCompaniesLoading = createSelector(
    [selectDomain],
    (companiesState) => companiesState.fetch.loading
);

export const selectCompaniesDone = createSelector(
    [selectDomain],
    (companiesState) => companiesState.fetch.done
);

export const selectCompaniesError = createSelector(
    [selectDomain],
    (companiesState) => companiesState.fetch.error
);
