import * as React from "react"

const ArrowUp = ({width, height}) => (
    <svg width={width} height={height} viewBox="0 0 15 8" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="m1.5 7 6-6 6 6"
            stroke="#53565A"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
)

export default ArrowUp;