import {isEmpty} from 'lodash';

const queryString = require('query-string');

export const objectToString = (params) => queryString.stringify(params)

export const objectToQueryString = (sourceUrl, queryParams) => {
    let formattedUrl = sourceUrl

    if (!isEmpty(queryParams)) {
        formattedUrl += '?'
        formattedUrl += objectToString(queryParams)
    }
    return formattedUrl
}


export const queryStringToObject = (query) => {
    return query ? queryString.parse(query.replace('?', '')) : {}
}

