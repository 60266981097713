import { createSelector } from "@reduxjs/toolkit";
import { initialState } from "./state";

const selectDomain = (state) => state.condition || initialState;

export const selectConditionData = createSelector(
    [selectDomain],
    (conditionState) => conditionState.data
);

export const selectConditionLoading = createSelector(
    [selectDomain],
    (conditionState) => conditionState.fetch.loading
);

export const selectConditionDone = createSelector(
    [selectDomain],
    (conditionState) => conditionState.fetch.done
);

export const selectConditionError = createSelector(
    [selectDomain],
    (conditionState) => conditionState.fetch.error
);
