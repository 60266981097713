import {useTranslation} from "react-i18next";
import React from "react";

// components
import SearchForm from "../../../components/SearchForm/";

// styles
import "./index.scss"

const Banner = () => {
  const {t} = useTranslation();
  return (
    <div className="banner">
      <div className="banner__container">
        <div className="banner__content">
          <div className="banner__inner">
            <h1 className="banner__title">{t('banner.title')}</h1>
            <h3 className="banner__subtitle">{t('banner.subtitle')}</h3>
          </div>
        </div>
        <SearchForm/>
      </div>
    </div>
  );
}

export default Banner;
