import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import SortIcon from "../../iconsComponents/SortIcon";
import {Popover} from "@material-ui/core";
import "./sortProducts.scss"

import {actions as sortingActions} from "../../store/modules/sortings/slice";
import {selectSortingData} from "../../store/modules/sortings/selectors";
import {useSearchParams} from "react-router-dom";

const SortProducts = ({params, setParams}) => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const sorting = useSelector(selectSortingData);
  const [anchorEl, setAnchorEl] = useState(false);
  const [sortName, setSortName] = useState("MostRelevant");

  let selectedSorting = searchParams.get("sortings")
  const sortOption = sorting && sorting.find(option => option.id.toString() === selectedSorting);
  const sortedName = sortOption ? sortOption.name : null;

  useEffect(() => {
    if (sortedName) {
      setSortName(sortedName);
    }
  }, [sortedName]);

  useEffect(() => {
    if (!sorting) dispatch(sortingActions.fetchDataTrigger());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(false);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleSorting = (event) => {
    let selectedSorting = event.target.dataset.id;
    const {pageNumber, ...clearedParams} = params;
    const newParams =
      selectedSorting
        ? Object.assign({}, clearedParams, {sortings: selectedSorting})
        : clearedParams;
    setParams(newParams);
  }

  return (
    <>
      <div className="catalog_list-sort" onClick={handleClick}>
        <SortIcon/>
        <p className="catalog_list-sort-title">{sortName}</p>
      </div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <div className="sort-wrapper">
          {sorting && sorting.map(sort => (
            <div
              key={sort.id}
              data-id={sort.id}
              className={((sortName === sort.name) ? "sort-active" : "")}
              onClick={handleSorting}>{sort.name}</div>
          ))}
        </div>
      </Popover>
    </>
  )
}

export default SortProducts;
