import React from "react";

const SmallLogo = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="44" height="50" viewBox="0 0 44 50" fill="none">
            <path
                d="M41.9778 15.9852C42.0212 15.8228 42.0005 15.65 41.92 15.5025C41.8395 15.3549 41.7054 15.244 41.5455 15.1925L34.1234 12.808L28.5093 11C28.4373 13.8431 25.699 16.1293 22.2271 16.1293C18.7552 16.1293 16.0104 13.8431 15.9383 11L2.45017 15.4022C2.28995 15.4549 2.15626 15.5675 2.07694 15.7163C1.99762 15.8652 1.97879 16.0389 2.02436 16.2014L3.98961 23.2762C4.01346 23.3618 4.05445 23.4416 4.11009 23.5108C4.16574 23.58 4.23487 23.6372 4.3133 23.6789C4.39173 23.7205 4.47781 23.7458 4.56631 23.7532C4.65481 23.7605 4.74388 23.7498 4.82812 23.7217L9.8919 22.0643V36.4172C9.89116 36.7855 9.99395 37.1466 10.1885 37.4594C10.3831 37.7721 10.6617 38.0238 10.9924 38.1859C13.8635 39.6644 16.0531 42.1942 17.1044 45.2477C17.2783 45.7672 17.6108 46.2192 18.0549 46.54C18.4991 46.8608 19.0327 47.0343 19.5806 47.0361H24.6574C25.2116 47.0348 25.7512 46.8578 26.1984 46.5306C26.6457 46.2033 26.9777 45.7427 27.1468 45.2149C28.1143 42.1895 30.2316 39.6652 33.0425 38.1859C33.37 38.0239 33.6459 37.7738 33.8392 37.4637C34.0324 37.1536 34.1354 36.7957 34.1365 36.4303V22.0774L39.1937 23.7217C39.2784 23.7497 39.3679 23.7602 39.4567 23.7524C39.5456 23.7446 39.6319 23.7187 39.7104 23.6764C39.7889 23.6341 39.8579 23.5762 39.9133 23.5063C39.9686 23.4363 40.0091 23.3558 40.0322 23.2697L41.9778 15.9852Z"
                stroke="#0E2F4B" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M25.0001 3H22.0091C22.0051 3.00016 21.997 3.00053 21.9854 3.00136C21.9573 3.00337 21.9106 3.00787 21.852 3.01764C21.73 3.03798 21.5826 3.07678 21.4473 3.14443C21.3159 3.21011 21.215 3.29294 21.1446 3.39845C21.0785 3.4977 21.0001 3.67498 21.0001 4C21.0001 4.47408 21.1752 4.67386 21.3344 4.78847C21.5445 4.93978 21.8268 5 22.0001 5H23.0001V6V7.29958L33.3417 11.0602C33.8608 11.2489 34.1285 11.8227 33.9398 12.3417C33.7511 12.8608 33.1773 13.1285 32.6583 12.9398L22.0119 9.06836L13.3624 12.432C12.8477 12.6322 12.2682 12.3772 12.068 11.8624C11.8678 11.3477 12.1228 10.7682 12.6376 10.568L21.0001 7.31593V6.83353C20.7215 6.74136 20.4348 6.60523 20.1658 6.41153C19.4916 5.92614 19.0001 5.12592 19.0001 4C19.0001 3.32502 19.1717 2.7523 19.4805 2.28905C19.7852 1.83206 20.1842 1.53989 20.5529 1.35557C20.9176 1.17322 21.2702 1.08702 21.5232 1.04486C21.652 1.02338 21.7616 1.01225 21.8429 1.00645C21.8837 1.00353 21.9179 1.00192 21.9443 1.00104C21.9575 1.0006 21.9689 1.00034 21.9782 1.00019L21.9907 1.00004L21.9958 1.00001L21.998 1L21.9991 1C21.9996 1 22.0001 1 22.0001 2V1H25.0001V3Z"
                  fill="#0E2F4B"/>
        </svg>
    );
}

export default SmallLogo;