import {reducer as products} from "./modules/products/slice";
import {reducer as materials} from "./modules/filters/materials/slice";
import {reducer as colors} from "./modules/filters/colors/slice";
import {reducer as categories} from "./modules/filters/categories/slice";
import {reducer as condition} from "./modules/filters/condition/slice";
import {reducer as companies} from "./modules/filters/companies/slice";
import {reducer as stock} from "./modules/filters/stock/slice";
import {reducer as shipping} from "./modules/filters/shipping/slice";
import {reducer as mainFiltered} from "./modules/mainFilteredProducts/slice";
import {reducer as location} from "./modules/localization/location/slice";
import {reducer as language} from "./modules/localization/language/slice";
import {reducer as currency} from "./modules/localization/currency/slice";
import {reducer as sorting} from "./modules/sortings/slice";
import {reducer as notifications} from "./modules/notifications/slice";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  products,
  materials,
  colors,
  categories,
  condition,
  companies,
  stock,
  shipping,
  mainFiltered,
  location,
  language,
  currency,
  sorting,
  notifications,
};
