import {queryStringToObject} from '../../utils/index'
import {FILTERS_TEMPLATE} from '../../utils/filters'

export const initRequestParams = {
  pageSize: localStorage.getItem('mobile') === 'true' ? 5 : 8,
  currency: localStorage.getItem('currencyId') ? localStorage.getItem('currencyId') : 2,
  // sortings: localStorage.getItem('sortingId'),
}

export const getInitParams = (query) => {
  let queryObject = queryStringToObject(query)
  const filtersNumberType = ['height', 'weight', 'priceFrom', 'priceTo'];
  let queryFilters = Object.keys(FILTERS_TEMPLATE).reduce((acc, key) => {

    let currentFilter = queryObject[key] ?
      {
        [key]: filtersNumberType.indexOf(key) >= 0 ?
          Number(queryObject[key]) :
          queryObject[key]
      } :
      {}

    return {
      ...acc,
      ...currentFilter
    }
  }, {})

  return {
    ...initRequestParams,
    ...queryFilters
  }
}
