import ApiConfig from "../../../config/api-config";
import {$apiClient} from "../../../utils/apiClient";
import {objectToQueryString} from "../../../utils";

export const getData = (params) => {
  let url = ApiConfig.SEARCH.GET_SIZE
  url = objectToQueryString(url, params)
  return $apiClient.get(url)
}

