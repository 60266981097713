import axios from "axios";
import ApiConfig from "../config/api-config";

const config = {
    baseURL: ApiConfig.BASE_URL,
    headers: {
        "Accept": "application/json",
        'Content-Type':
            'application/json;multipart/form-data',
        "x-localization": "heb",
    },
};

const $apiClient = axios.create(config);

export { $apiClient };
