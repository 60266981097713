import {takeLatest} from "@redux-saga/core/effects";
import {fetchProductsSaga} from "./modules/products/saga";
import {actions as productsActions} from "./modules/products/slice";
import {fetchMaterialsSaga} from "./modules/filters/materials/saga";
import {actions as materialsActions} from "./modules/filters/materials/slice";
import {fetchColorsSaga} from "./modules/filters/colors/saga";
import {actions as colorsActions} from "./modules/filters/colors/slice";
import {fetchCategoriesSaga} from "./modules/filters/categories/saga";
import {actions as categoriesActions} from "./modules/filters/categories/slice";
import {fetchConditionSaga} from "./modules/filters/condition/saga";
import {actions as conditionActions} from "./modules/filters/condition/slice";
import {fetchCompaniesSaga} from "./modules/filters/companies/saga";
import {actions as companiesActions} from "./modules/filters/companies/slice";
import {fetchStockSaga} from "./modules/filters/stock/saga";
import {actions as stockActions} from "./modules/filters/stock/slice";
import {fetchShippingSaga} from "./modules/filters/shipping/saga";
import {actions as shippingActions} from "./modules/filters/shipping/slice";
import {fetchMainFilteredSaga} from "./modules/mainFilteredProducts/saga";
import {actions as mainFilteredActions} from "./modules/mainFilteredProducts/slice";
import {fetchLocationSaga} from "./modules/localization/location/saga";
import {actions as locationActions} from "./modules/localization/location/slice";
import {fetchLanguageSaga} from "./modules/localization/language/saga";
import {actions as languageActions} from "./modules/localization/language/slice";
import {fetchCurrencySaga} from "./modules/localization/currency/saga";
import {actions as currencyActions} from "./modules/localization/currency/slice";
import {fetchSortingSaga} from "./modules/sortings/saga";
import {actions as sortingActions} from "./modules/sortings/slice";
import {fetchNotificationSaga} from "./modules/notifications/saga";
import {actions as notificationsActions} from "./modules/notifications/slice";


export function* watchSagas() {
  yield takeLatest(
    productsActions.fetchDataTrigger.type,
    fetchProductsSaga
  );
  yield takeLatest(
    materialsActions.fetchDataTrigger.type,
    fetchMaterialsSaga
  );
  yield takeLatest(
    colorsActions.fetchDataTrigger.type,
    fetchColorsSaga
  );
  yield takeLatest(
    categoriesActions.fetchDataTrigger.type,
    fetchCategoriesSaga
  );
  yield takeLatest(
    conditionActions.fetchDataTrigger.type,
    fetchConditionSaga
  );
  yield takeLatest(
    companiesActions.fetchDataTrigger.type,
    fetchCompaniesSaga
  );
  yield takeLatest(
    stockActions.fetchDataTrigger.type,
    fetchStockSaga
  );
  yield takeLatest(
    shippingActions.fetchDataTrigger.type,
    fetchShippingSaga
  );
  yield takeLatest(
    mainFilteredActions.fetchDataTrigger.type,
    fetchMainFilteredSaga
  );
  yield takeLatest(
    locationActions.fetchDataTrigger.type,
    fetchLocationSaga
  );
  yield takeLatest(
    languageActions.fetchDataTrigger.type,
    fetchLanguageSaga
  );
  yield takeLatest(
    currencyActions.fetchDataTrigger.type,
    fetchCurrencySaga
  );
  yield takeLatest(
    sortingActions.fetchDataTrigger.type,
    fetchSortingSaga
  );
  yield takeLatest(
    notificationsActions.fetchDataTrigger.type,
    fetchNotificationSaga
  );
}

