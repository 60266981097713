import { createSelector } from "@reduxjs/toolkit";
import { initialState } from "./state";

const selectDomain = (state) => state.sorting || initialState;

export const selectSortingData = createSelector(
    [selectDomain],
    (parametersState) => parametersState.data
);

export const selectSortingLoading = createSelector(
    [selectDomain],
    (parametersState) => parametersState.fetch.loading
);

export const selectSortingDone = createSelector(
    [selectDomain],
    (parametersState) => parametersState.fetch.done
);

export const selectSortingError = createSelector(
    [selectDomain],
    (parametersState) => parametersState.fetch.error
);
