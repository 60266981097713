import { call, put } from "redux-saga/effects";
import { actions as materialsActions } from "./slice";
import * as materialsServices from "./services";

/**
 * Fetch parameters request/response handler
 */
export function* fetchMaterialsSaga() {
    try {
        const { data } = yield call(materialsServices.getData);

        yield put(materialsActions.fetchDataSuccess(data));
    } catch (err) {
        yield put(materialsActions.fetchDataFailed(err));
    } finally {
        yield put(materialsActions.fetchDataFulfilled())
    }
}
