import { call, put } from "redux-saga/effects";
import { actions as mainFilteredActions } from "./slice";
import * as mainFilteredServices from "./services";

/**
 * Fetch parameters request/response handler
 */
export function* fetchMainFilteredSaga({ payload }) {
    try {
        const { data } = yield call(mainFilteredServices.getData, payload);

        yield put(mainFilteredActions.fetchDataSuccess(data));
    } catch (err) {
        yield put(mainFilteredActions.fetchDataFailed(err));
    } finally {
        yield put(mainFilteredActions.fetchDataFulfilled())
    }
}
