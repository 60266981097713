import React from "react";

const Logo = () => {
    return (
        <svg width="186" height="23" className="logo-title" viewBox="0 0 186 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.576 17.76C0.416 17.76 0.28 17.704 0.168 17.592C0.056 17.48 0 17.344 0 17.184V1.56C0 1.384 0.056 1.24 0.168 1.128C0.28 1.016 0.416 0.959999 0.576 0.959999H7.008C8.256 0.959999 9.344 1.16 10.272 1.56C11.2 1.944 11.92 2.536 12.432 3.336C12.944 4.12 13.2 5.104 13.2 6.288C13.2 7.488 12.944 8.48 12.432 9.264C11.92 10.032 11.2 10.608 10.272 10.992C9.344 11.376 8.256 11.568 7.008 11.568H3.432V17.184C3.432 17.344 3.376 17.48 3.264 17.592C3.168 17.704 3.032 17.76 2.856 17.76H0.576ZM3.384 8.904H6.888C7.816 8.904 8.528 8.688 9.024 8.256C9.52 7.808 9.768 7.152 9.768 6.288C9.768 5.488 9.536 4.848 9.072 4.368C8.608 3.872 7.88 3.624 6.888 3.624H3.384V8.904Z" fill="#0E2F4B"/>
                <path d="M20.7443 18C18.9683 18 17.5602 17.488 16.5203 16.464C15.4803 15.44 14.9202 13.984 14.8403 12.096C14.8243 11.936 14.8162 11.736 14.8162 11.496C14.8162 11.24 14.8243 11.04 14.8403 10.896C14.9043 9.696 15.1763 8.656 15.6562 7.776C16.1523 6.896 16.8323 6.224 17.6963 5.76C18.5603 5.28 19.5763 5.04 20.7443 5.04C22.0403 5.04 23.1283 5.312 24.0083 5.856C24.8883 6.384 25.5522 7.128 26.0002 8.088C26.4482 9.032 26.6723 10.128 26.6723 11.376V11.88C26.6723 12.04 26.6163 12.176 26.5043 12.288C26.3923 12.4 26.2482 12.456 26.0723 12.456H18.1042C18.1042 12.456 18.1042 12.48 18.1042 12.528C18.1042 12.576 18.1042 12.616 18.1042 12.648C18.1203 13.208 18.2243 13.728 18.4163 14.208C18.6243 14.672 18.9202 15.048 19.3043 15.336C19.7043 15.624 20.1763 15.768 20.7203 15.768C21.1683 15.768 21.5443 15.704 21.8483 15.576C22.1523 15.432 22.4003 15.272 22.5923 15.096C22.7843 14.92 22.9202 14.776 23.0002 14.664C23.1442 14.472 23.2563 14.36 23.3363 14.328C23.4323 14.28 23.5683 14.256 23.7443 14.256H25.8083C25.9683 14.256 26.0963 14.304 26.1923 14.4C26.3043 14.496 26.3523 14.616 26.3363 14.76C26.3203 15.016 26.1842 15.328 25.9283 15.696C25.6883 16.064 25.3363 16.424 24.8723 16.776C24.4083 17.128 23.8243 17.424 23.1203 17.664C22.4323 17.888 21.6403 18 20.7443 18ZM18.1042 10.44H23.4083V10.368C23.4083 9.744 23.3043 9.2 23.0963 8.736C22.8883 8.272 22.5843 7.904 22.1843 7.632C21.7843 7.36 21.3043 7.224 20.7443 7.224C20.1843 7.224 19.7043 7.36 19.3043 7.632C18.9043 7.904 18.6003 8.272 18.3923 8.736C18.2003 9.2 18.1042 9.744 18.1042 10.368V10.44Z" fill="#0E2F4B"/>
                <path d="M29.7958 17.76C29.6358 17.76 29.4998 17.704 29.3878 17.592C29.2758 17.48 29.2198 17.344 29.2198 17.184V5.88C29.2198 5.704 29.2758 5.56 29.3878 5.448C29.4998 5.336 29.6358 5.28 29.7958 5.28H31.7398C31.8998 5.28 32.0358 5.336 32.1478 5.448C32.2758 5.56 32.3398 5.704 32.3398 5.88V6.864C32.7078 6.352 33.1798 5.96 33.7558 5.688C34.3478 5.416 35.0278 5.28 35.7958 5.28H36.7798C36.9558 5.28 37.0918 5.336 37.1878 5.448C37.2998 5.56 37.3558 5.696 37.3558 5.856V7.584C37.3558 7.744 37.2998 7.88 37.1878 7.992C37.0918 8.104 36.9558 8.16 36.7798 8.16H34.9078C34.1398 8.16 33.5398 8.376 33.1078 8.808C32.6918 9.24 32.4838 9.832 32.4838 10.584V17.184C32.4838 17.344 32.4278 17.48 32.3158 17.592C32.2038 17.704 32.0598 17.76 31.8838 17.76H29.7958Z" fill="#0E2F4B"/>
                <path d="M41.1476 17.76C40.9876 17.76 40.8516 17.704 40.7396 17.592C40.6276 17.48 40.5716 17.344 40.5716 17.184V7.824H38.6276C38.4676 7.824 38.3316 7.768 38.2196 7.656C38.1076 7.544 38.0516 7.408 38.0516 7.248V5.856C38.0516 5.696 38.1076 5.56 38.2196 5.448C38.3316 5.336 38.4676 5.28 38.6276 5.28H40.5716V4.296C40.5716 3.336 40.7476 2.544 41.0996 1.92C41.4516 1.28 41.9716 0.799999 42.6596 0.48C43.3476 0.16 44.2196 0 45.2756 0H46.9076C47.0836 0 47.2196 0.0559998 47.3156 0.167999C47.4276 0.28 47.4836 0.416 47.4836 0.576V1.968C47.4836 2.128 47.4276 2.264 47.3156 2.376C47.2196 2.488 47.0836 2.544 46.9076 2.544H45.3716C44.7316 2.544 44.2916 2.704 44.0516 3.024C43.8116 3.344 43.6916 3.808 43.6916 4.416V5.28H46.6676C46.8436 5.28 46.9796 5.336 47.0756 5.448C47.1876 5.56 47.2436 5.696 47.2436 5.856V7.248C47.2436 7.408 47.1876 7.544 47.0756 7.656C46.9796 7.768 46.8436 7.824 46.6676 7.824H43.6916V17.184C43.6916 17.344 43.6356 17.48 43.5236 17.592C43.4116 17.704 43.2756 17.76 43.1156 17.76H41.1476Z" fill="#0E2F4B"/>
                <path d="M54.3536 18C52.5776 18 51.1696 17.488 50.1296 16.464C49.0896 15.44 48.5296 13.984 48.4496 12.096C48.4336 11.936 48.4256 11.736 48.4256 11.496C48.4256 11.24 48.4336 11.04 48.4496 10.896C48.5136 9.696 48.7856 8.656 49.2656 7.776C49.7616 6.896 50.4416 6.224 51.3056 5.76C52.1696 5.28 53.1856 5.04 54.3536 5.04C55.6496 5.04 56.7376 5.312 57.6176 5.856C58.4976 6.384 59.1616 7.128 59.6096 8.088C60.0576 9.032 60.2816 10.128 60.2816 11.376V11.88C60.2816 12.04 60.2256 12.176 60.1136 12.288C60.0016 12.4 59.8576 12.456 59.6816 12.456H51.7136C51.7136 12.456 51.7136 12.48 51.7136 12.528C51.7136 12.576 51.7136 12.616 51.7136 12.648C51.7296 13.208 51.8336 13.728 52.0256 14.208C52.2336 14.672 52.5296 15.048 52.9136 15.336C53.3136 15.624 53.7856 15.768 54.3296 15.768C54.7776 15.768 55.1536 15.704 55.4576 15.576C55.7616 15.432 56.0096 15.272 56.2016 15.096C56.3936 14.92 56.5296 14.776 56.6096 14.664C56.7536 14.472 56.8656 14.36 56.9456 14.328C57.0416 14.28 57.1776 14.256 57.3536 14.256H59.4176C59.5776 14.256 59.7056 14.304 59.8016 14.4C59.9136 14.496 59.9616 14.616 59.9456 14.76C59.9296 15.016 59.7936 15.328 59.5376 15.696C59.2976 16.064 58.9456 16.424 58.4816 16.776C58.0176 17.128 57.4336 17.424 56.7296 17.664C56.0416 17.888 55.2496 18 54.3536 18ZM51.7136 10.44H57.0176V10.368C57.0176 9.744 56.9136 9.2 56.7056 8.736C56.4976 8.272 56.1936 7.904 55.7936 7.632C55.3936 7.36 54.9136 7.224 54.3536 7.224C53.7936 7.224 53.3136 7.36 52.9136 7.632C52.5136 7.904 52.2096 8.272 52.0016 8.736C51.8096 9.2 51.7136 9.744 51.7136 10.368V10.44Z" fill="#0E2F4B"/>
                <path d="M68.2292 18C67.0772 18 66.0612 17.784 65.1812 17.352C64.3172 16.92 63.6372 16.296 63.1412 15.48C62.6612 14.648 62.3972 13.648 62.3492 12.48C62.3332 12.24 62.3252 11.928 62.3252 11.544C62.3252 11.144 62.3332 10.816 62.3492 10.56C62.3972 9.392 62.6612 8.4 63.1412 7.584C63.6372 6.752 64.3172 6.12 65.1812 5.688C66.0612 5.256 67.0772 5.04 68.2292 5.04C69.2532 5.04 70.1252 5.184 70.8452 5.472C71.5652 5.76 72.1572 6.12 72.6212 6.552C73.0852 6.984 73.4292 7.44 73.6532 7.92C73.8932 8.384 74.0212 8.808 74.0372 9.192C74.0532 9.368 73.9972 9.512 73.8692 9.624C73.7572 9.72 73.6212 9.768 73.4612 9.768H71.3492C71.1892 9.768 71.0612 9.728 70.9652 9.648C70.8852 9.568 70.8052 9.448 70.7252 9.288C70.4852 8.648 70.1652 8.2 69.7652 7.944C69.3812 7.672 68.8852 7.536 68.2772 7.536C67.4772 7.536 66.8372 7.792 66.3572 8.304C65.8932 8.8 65.6452 9.592 65.6132 10.68C65.5812 11.288 65.5812 11.848 65.6132 12.36C65.6452 13.464 65.8932 14.264 66.3572 14.76C66.8372 15.256 67.4772 15.504 68.2772 15.504C68.9012 15.504 69.4052 15.376 69.7892 15.12C70.1732 14.848 70.4852 14.392 70.7252 13.752C70.8052 13.592 70.8852 13.472 70.9652 13.392C71.0612 13.312 71.1892 13.272 71.3492 13.272H73.4612C73.6212 13.272 73.7572 13.328 73.8692 13.44C73.9972 13.536 74.0532 13.672 74.0372 13.848C74.0212 14.136 73.9412 14.464 73.7972 14.832C73.6692 15.184 73.4532 15.552 73.1492 15.936C72.8612 16.304 72.4932 16.648 72.0452 16.968C71.5972 17.272 71.0532 17.52 70.4132 17.712C69.7892 17.904 69.0612 18 68.2292 18Z" fill="#0E2F4B"/>
                <path d="M82.1801 17.76C81.2201 17.76 80.4121 17.592 79.7561 17.256C79.1001 16.92 78.6121 16.424 78.2921 15.768C77.9721 15.096 77.8121 14.264 77.8121 13.272V7.824H75.9161C75.7561 7.824 75.6201 7.768 75.5081 7.656C75.3961 7.544 75.3401 7.408 75.3401 7.248V5.856C75.3401 5.696 75.3961 5.56 75.5081 5.448C75.6201 5.336 75.7561 5.28 75.9161 5.28H77.8121V1.296C77.8121 1.136 77.8601 1 77.9561 0.888C78.0681 0.776 78.2121 0.719999 78.3881 0.719999H80.3321C80.4921 0.719999 80.6281 0.776 80.7401 0.888C80.8521 1 80.9081 1.136 80.9081 1.296V5.28H83.9081C84.0681 5.28 84.2041 5.336 84.3161 5.448C84.4281 5.56 84.4841 5.696 84.4841 5.856V7.248C84.4841 7.408 84.4281 7.544 84.3161 7.656C84.2041 7.768 84.0681 7.824 83.9081 7.824H80.9081V13.032C80.9081 13.688 81.0201 14.2 81.2441 14.568C81.4841 14.936 81.8921 15.12 82.4681 15.12H84.1241C84.2841 15.12 84.4201 15.176 84.5321 15.288C84.6441 15.4 84.7001 15.536 84.7001 15.696V17.184C84.7001 17.344 84.6441 17.48 84.5321 17.592C84.4201 17.704 84.2841 17.76 84.1241 17.76H82.1801Z" fill="#0E2F4B"/>
                <path d="M93.1776 17.76C93.0176 17.76 92.8816 17.704 92.7696 17.592C92.6576 17.48 92.6016 17.344 92.6016 17.184V1.56C92.6016 1.384 92.6576 1.24 92.7696 1.128C92.8816 1.016 93.0176 0.959999 93.1776 0.959999H100.138C101.434 0.959999 102.49 1.16 103.306 1.56C104.122 1.944 104.722 2.48 105.106 3.168C105.506 3.856 105.706 4.656 105.706 5.568C105.706 6.176 105.594 6.712 105.37 7.176C105.162 7.624 104.898 7.992 104.578 8.28C104.274 8.568 103.97 8.792 103.666 8.952C104.29 9.24 104.842 9.72 105.322 10.392C105.802 11.064 106.042 11.864 106.042 12.792C106.042 13.752 105.826 14.608 105.394 15.36C104.962 16.112 104.322 16.704 103.474 17.136C102.626 17.552 101.578 17.76 100.33 17.76H93.1776ZM95.9376 15.216H99.9456C100.81 15.216 101.466 14.984 101.914 14.52C102.362 14.04 102.586 13.464 102.586 12.792C102.586 12.088 102.354 11.504 101.89 11.04C101.442 10.576 100.794 10.344 99.9456 10.344H95.9376V15.216ZM95.9376 7.848H99.7056C100.554 7.848 101.186 7.648 101.602 7.248C102.034 6.832 102.25 6.296 102.25 5.64C102.25 4.968 102.034 4.448 101.602 4.08C101.186 3.696 100.554 3.504 99.7056 3.504H95.9376V7.848Z" fill="#0E2F4B"/>
                <path d="M112.391 18C111.559 18 110.807 17.84 110.135 17.52C109.463 17.184 108.927 16.744 108.527 16.2C108.143 15.64 107.951 15.016 107.951 14.328C107.951 13.208 108.399 12.32 109.295 11.664C110.207 10.992 111.407 10.544 112.895 10.32L116.183 9.84V9.336C116.183 8.664 116.015 8.144 115.679 7.776C115.343 7.408 114.767 7.224 113.951 7.224C113.375 7.224 112.903 7.344 112.535 7.584C112.183 7.808 111.919 8.112 111.743 8.496C111.615 8.704 111.431 8.808 111.191 8.808H109.295C109.119 8.808 108.983 8.76 108.887 8.664C108.807 8.568 108.767 8.44 108.767 8.28C108.783 8.024 108.887 7.712 109.079 7.344C109.271 6.976 109.575 6.624 109.991 6.288C110.407 5.936 110.943 5.64 111.599 5.4C112.255 5.16 113.047 5.04 113.975 5.04C114.983 5.04 115.831 5.168 116.519 5.424C117.223 5.664 117.783 5.992 118.199 6.408C118.615 6.824 118.919 7.312 119.111 7.872C119.303 8.432 119.399 9.024 119.399 9.648V17.184C119.399 17.344 119.343 17.48 119.231 17.592C119.119 17.704 118.983 17.76 118.823 17.76H116.879C116.703 17.76 116.559 17.704 116.447 17.592C116.351 17.48 116.303 17.344 116.303 17.184V16.248C116.095 16.552 115.815 16.84 115.463 17.112C115.111 17.368 114.679 17.584 114.167 17.76C113.671 17.92 113.079 18 112.391 18ZM113.207 15.72C113.767 15.72 114.271 15.6 114.719 15.36C115.183 15.12 115.543 14.752 115.799 14.256C116.071 13.744 116.207 13.104 116.207 12.336V11.832L113.807 12.216C112.863 12.36 112.159 12.592 111.695 12.912C111.231 13.232 110.999 13.624 110.999 14.088C110.999 14.44 111.103 14.744 111.311 15C111.535 15.24 111.815 15.424 112.151 15.552C112.487 15.664 112.839 15.72 113.207 15.72Z" fill="#0E2F4B"/>
                <path d="M129.39 18C128.462 18 127.686 17.84 127.062 17.52C126.454 17.2 125.95 16.792 125.55 16.296V17.184C125.55 17.344 125.494 17.48 125.382 17.592C125.286 17.704 125.15 17.76 124.974 17.76H123.078C122.918 17.76 122.782 17.704 122.67 17.592C122.558 17.48 122.502 17.344 122.502 17.184V1.296C122.502 1.136 122.558 1 122.67 0.888C122.782 0.776 122.918 0.719999 123.078 0.719999H125.118C125.294 0.719999 125.43 0.776 125.526 0.888C125.638 1 125.694 1.136 125.694 1.296V6.624C126.094 6.16 126.59 5.784 127.182 5.496C127.79 5.192 128.526 5.04 129.39 5.04C130.238 5.04 130.974 5.184 131.598 5.472C132.222 5.76 132.742 6.168 133.158 6.696C133.59 7.208 133.918 7.808 134.142 8.496C134.366 9.184 134.486 9.928 134.502 10.728C134.518 11.016 134.526 11.28 134.526 11.52C134.526 11.744 134.518 12 134.502 12.288C134.47 13.12 134.342 13.888 134.118 14.592C133.894 15.28 133.574 15.88 133.158 16.392C132.742 16.904 132.222 17.304 131.598 17.592C130.974 17.864 130.238 18 129.39 18ZM128.502 15.432C129.19 15.432 129.726 15.288 130.11 15C130.494 14.696 130.766 14.304 130.926 13.824C131.102 13.344 131.206 12.816 131.238 12.24C131.27 11.76 131.27 11.28 131.238 10.8C131.206 10.224 131.102 9.696 130.926 9.216C130.766 8.736 130.494 8.352 130.11 8.064C129.726 7.76 129.19 7.608 128.502 7.608C127.862 7.608 127.342 7.752 126.942 8.04C126.542 8.328 126.238 8.696 126.03 9.144C125.822 9.576 125.71 10.032 125.694 10.512C125.678 10.8 125.67 11.112 125.67 11.448C125.67 11.768 125.678 12.072 125.694 12.36C125.726 12.856 125.83 13.344 126.006 13.824C126.198 14.288 126.494 14.672 126.894 14.976C127.31 15.28 127.846 15.432 128.502 15.432Z" fill="#0E2F4B"/>
                <path d="M138.96 22.32C138.832 22.32 138.72 22.272 138.624 22.176C138.528 22.08 138.48 21.968 138.48 21.84C138.48 21.776 138.48 21.712 138.48 21.648C138.496 21.6 138.528 21.536 138.576 21.456L140.448 17.016L135.84 6.144C135.776 5.984 135.744 5.872 135.744 5.808C135.76 5.664 135.816 5.544 135.912 5.448C136.008 5.336 136.128 5.28 136.272 5.28H138.216C138.408 5.28 138.552 5.328 138.648 5.424C138.744 5.52 138.816 5.624 138.864 5.736L142.056 13.584L145.344 5.736C145.392 5.608 145.464 5.504 145.56 5.424C145.656 5.328 145.8 5.28 145.992 5.28H147.912C148.04 5.28 148.152 5.328 148.248 5.424C148.36 5.52 148.416 5.632 148.416 5.76C148.416 5.856 148.384 5.984 148.32 6.144L141.528 21.864C141.464 21.992 141.384 22.096 141.288 22.176C141.192 22.272 141.048 22.32 140.856 22.32H138.96Z" fill="#0E2F4B"/>
                <path d="M156.834 17.76C156.674 17.76 156.538 17.704 156.426 17.592C156.314 17.48 156.258 17.344 156.258 17.184V1.56C156.258 1.384 156.314 1.24 156.426 1.128C156.538 1.016 156.674 0.959999 156.834 0.959999H167.25C167.426 0.959999 167.57 1.016 167.682 1.128C167.794 1.24 167.85 1.384 167.85 1.56V3.24C167.85 3.416 167.794 3.56 167.682 3.672C167.57 3.768 167.426 3.816 167.25 3.816H159.546V8.376H166.77C166.946 8.376 167.09 8.432 167.202 8.544C167.314 8.656 167.37 8.8 167.37 8.976V10.656C167.37 10.816 167.314 10.952 167.202 11.064C167.09 11.176 166.946 11.232 166.77 11.232H159.546V17.184C159.546 17.344 159.49 17.48 159.378 17.592C159.266 17.704 159.122 17.76 158.946 17.76H156.834Z" fill="#0E2F4B"/>
                <path d="M171.171 17.76C171.011 17.76 170.875 17.704 170.763 17.592C170.651 17.48 170.595 17.344 170.595 17.184V5.856C170.595 5.696 170.651 5.56 170.763 5.448C170.875 5.336 171.011 5.28 171.171 5.28H173.163C173.339 5.28 173.475 5.336 173.571 5.448C173.683 5.56 173.739 5.696 173.739 5.856V17.184C173.739 17.344 173.683 17.48 173.571 17.592C173.475 17.704 173.339 17.76 173.163 17.76H171.171ZM171.075 3.216C170.899 3.216 170.755 3.168 170.643 3.072C170.547 2.96 170.499 2.816 170.499 2.64V0.912001C170.499 0.752001 170.547 0.616 170.643 0.504C170.755 0.392 170.899 0.336 171.075 0.336H173.259C173.435 0.336 173.579 0.392 173.691 0.504C173.803 0.616 173.859 0.752001 173.859 0.912001V2.64C173.859 2.816 173.803 2.96 173.691 3.072C173.579 3.168 173.435 3.216 173.259 3.216H171.075Z" fill="#0E2F4B"/>
                <path d="M182.563 17.76C181.603 17.76 180.795 17.592 180.139 17.256C179.483 16.92 178.995 16.424 178.675 15.768C178.355 15.096 178.195 14.264 178.195 13.272V7.824H176.299C176.139 7.824 176.003 7.768 175.891 7.656C175.779 7.544 175.723 7.408 175.723 7.248V5.856C175.723 5.696 175.779 5.56 175.891 5.448C176.003 5.336 176.139 5.28 176.299 5.28H178.195V1.296C178.195 1.136 178.243 1 178.339 0.888C178.451 0.776 178.595 0.719999 178.771 0.719999H180.715C180.875 0.719999 181.011 0.776 181.123 0.888C181.235 1 181.291 1.136 181.291 1.296V5.28H184.291C184.451 5.28 184.587 5.336 184.699 5.448C184.811 5.56 184.867 5.696 184.867 5.856V7.248C184.867 7.408 184.811 7.544 184.699 7.656C184.587 7.768 184.451 7.824 184.291 7.824H181.291V13.032C181.291 13.688 181.403 14.2 181.627 14.568C181.867 14.936 182.275 15.12 182.851 15.12H184.507C184.667 15.12 184.803 15.176 184.915 15.288C185.027 15.4 185.083 15.536 185.083 15.696V17.184C185.083 17.344 185.027 17.48 184.915 17.592C184.803 17.704 184.667 17.76 184.507 17.76H182.563Z" fill="#0E2F4B"/>
        </svg>

    );
}

export default Logo;