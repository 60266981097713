export const DEFAULT_LOCALE = 'en';

export const languagesList = [
    {
        id: 'en',
        short: 'EN',
        label: 'English',
    },
    {
        id: 'heb',
        short: 'HEB',
        label: 'Hebrew',
    }
];
