import React from "react";
import {Outlet} from "react-router-dom";

// components
import Header from "./Header";
import Footer from "./Footer";

const Layout = () => {

  return (
    <div>
      <header>
        <Header/>
      </header>

      <main>
        <div>
          <Outlet/>
        </div>
      </main>

      <footer>
        <Footer/>
      </footer>
    </div>
  );
};

export default Layout;
