import { call, put } from "redux-saga/effects";
import { actions as currencyActions } from "./slice";
import * as currencyServices from "./services";

/**
 * Fetch parameters request/response handler
 */
export function* fetchCurrencySaga() {
    try {
        const { data } = yield call(currencyServices.getData);

        yield put(currencyActions.fetchDataSuccess(data));
    } catch (err) {
        yield put(currencyActions.fetchDataFailed(err));
    } finally {
        yield put(currencyActions.fetchDataFulfilled())
    }
}