import * as React from "react"

const CloseIcon = () => (
    <svg width={24} height={24} fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#a)">
            <path d="M24 0H0v24h24V0Z" fill="#fff" fillOpacity={0.01} />
            <path
                d="m7 7 10 10M7 17 17 7"
                stroke="#ADADAD"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </g>
        <defs>
            <clipPath id="a">
                <path fill="#fff" d="M0 0h24v24H0z" />
            </clipPath>
        </defs>
    </svg>
)

export default CloseIcon;