export const translations = {};

export const convertLanguageJsonToObject = (json, objToConvertTo = translations, current,) => {
    Object.keys(json).forEach(key => {
        const currentLookupKey = current ? `${current}.${key}` : key;
        if (typeof json[key] === 'object') {
            objToConvertTo[key] = {};
            convertLanguageJsonToObject(json[key], objToConvertTo[key], currentLookupKey,);
        } else {
            objToConvertTo[key] = currentLookupKey;
        }
    });
};
