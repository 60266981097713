export const GENDER_TYPE = {
  ALL: "All",
  BOY: "Boy",
  GIRL: "Girl",
};


export const initErrors = () => {
  return {
    gender: '',
    birthDate: '',
    height: '',
    weight: '',
  }
}
