import { createSelector } from "@reduxjs/toolkit";
import { initialState } from "./state";

const selectDomain = (state) => state.stock || initialState;

export const selectStockData = createSelector(
    [selectDomain],
    (stockState) => stockState.data
);

export const selectStockLoading = createSelector(
    [selectDomain],
    (stockState) => stockState.fetch.loading
);

export const selectStockDone = createSelector(
    [selectDomain],
    (stockState) => stockState.fetch.done
);

export const selectStockError = createSelector(
    [selectDomain],
    (stockState) => stockState.fetch.error
);
