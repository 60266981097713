import { call, put } from "redux-saga/effects";
import { actions as companiesActions } from "./slice";
import * as companiesServices from "./services";

/**
 * Fetch parameters request/response handler
 */
export function* fetchCompaniesSaga() {
    try {
        const {data} = yield call(companiesServices.getData);

        yield put(companiesActions.fetchDataSuccess(data));
    } catch (err) {
        yield put(companiesActions.fetchDataFailed(err));
    } finally {
        yield put(companiesActions.fetchDataFulfilled())
    }
}