import { createSelector } from "@reduxjs/toolkit";
import { initialState } from "./state";

const selectDomain = (state) => state.categories || initialState;

export const selectCategoriesData = createSelector(
    [selectDomain],
    (categoriesState) => categoriesState.data
);

export const selectCategoriesLoading = createSelector(
    [selectDomain],
    (categoriesState) => categoriesState.fetch.loading
);

export const selectCategoriesDone = createSelector(
    [selectDomain],
    (categoriesState) => categoriesState.fetch.done
);

export const selectCategoriesError = createSelector(
    [selectDomain],
    (categoriesState) => categoriesState.fetch.error
);
