import {initialState} from "./state";
import {createSlice} from "@reduxjs/toolkit";

const appSlice = createSlice({
  name: "mainFiltered",
  initialState,
  reducers: {
    // fetch
    fetchDataTrigger(state) {
      state.fetch.loading = true;
      state.fetch.done = false;
      state.fetch.error = null;
      state.data = null;
    },
    fetchDataSuccess(state, action) {
      state.fetch.done = true;
      state.fetch.loading = false;
      state.data = action.payload;
    },
    fetchDataFailed(state, action) {
      state.fetch.error = action.payload;
      state.fetch.loading = false;
    },
    fetchDataFulfilled(state) {
      state.fetch.done = false;

    },
  },
});

export const {actions, reducer, name} = appSlice;
