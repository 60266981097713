// core
import React, {useState} from "react";
import Modal from "react-modal";
import {useTranslation} from "react-i18next";

// components
import ProductCard from "../productCard/ProductCard";
import Loader from "../../iconsComponents/Loader";
import ModalForStock from "../modalForStock/ModalForStock";

// style
import "./index.scss";

const CatalogList = ({mainFiltered, params, setParams}) => {
  const {t} = useTranslation();

  const [isOpen, setIsOpen] = useState(false);
  const [sizeForModal, setSizeForModal] = useState("");
  const [linkForModal, setLinkForModal] = useState("");
  const listOfProducts = mainFiltered?.responseList;

  function toggleModal() {
    setIsOpen(!isOpen);
  }

  const handleLoadMoreClick = () => {
    let selectedPage;
    if (params.pageNumber === undefined) {
      selectedPage = 2;
    } else {
      selectedPage = Number(params.pageNumber) + 1;
    }
    const {pageNumber, ...clearedParams} = params;
    const newParams =
      selectedPage && selectedPage > 1
        ? Object.assign({}, clearedParams, {pageNumber: selectedPage})
        : clearedParams;
    setParams(newParams);
  }
  return (
    <div className="catalog_list-wrapper">

      {listOfProducts &&
        listOfProducts.map((card, index) => (
          <ProductCard key={index} card={card} size={setSizeForModal} link={setLinkForModal}
                       toggleModal={toggleModal}/>
        ))}
      <Modal
        isOpen={isOpen}
        onRequestClose={toggleModal}
        contentLabel="My dialog"
        className="Modal"
        overlayClassName="Overlay"
        appElement={document.getElementById("root")}
      >
        <ModalForStock toggleModal={toggleModal} size={sizeForModal} link={linkForModal}/>
      </Modal>
      {mainFiltered?.totalItems > 8 &&
        <div className="card-wrapper card-wrapper-loader" onClick={handleLoadMoreClick}>
          <div className="card-wrapper-card">
            <div className="card_loader">
              <Loader/>
              <p className="card_loader-title">{t('products.moreResults')}</p>
            </div>
          </div>
        </div>
      }
    </div>
  );
};

export default CatalogList;
