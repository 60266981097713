import React, {useState} from "react";
import {addDays, getMonth, getYear} from "date-fns";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import DatePicker from "react-datepicker";
import {range} from "lodash";

//utils
import {FormSchema} from "../../utils/filters";

// store
import {selectMainFilteredError} from "../../store/modules/mainFilteredProducts/selectors";

// styles
import 'react-datepicker/dist/react-datepicker.css'
import "./index.scss";


// data
import {GENDER_TYPE, initErrors} from "./data";

const SizeForm = ({customStyle, enteredSize, onClick, isCatalog}) => {
  const {t} = useTranslation();
  const mainFilteredError = useSelector(selectMainFilteredError)

  const [sizes, setSizes] = useState(enteredSize);
  const [startDate] = useState(new Date());
  const [formErrors, setFormErrors] = useState(initErrors());

  const years = range(1990, getYear(new Date()) + 1, 1);
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  //gender = All
  const onCheckboxHandler = (event) => {
    const {name, checked} = event.target;
    // const { gender } = sizes;
    let currentGender = "";
    if (checked) {
      currentGender = name;
    }

    setSizes({...sizes, gender: currentGender});
  };

  const onClickHandler = (e) => {
    e.preventDefault();
    setFormErrors(initErrors());
    if (isCatalog) {
      onClick(sizes);
    } else {
      FormSchema.validate(
        {
          gender: sizes.gender,
          birthDate: sizes.birthDate,
          height: sizes.height,
          weight: sizes.weight,
        },
        {
          abortEarly: false,
        }
      )
        .then((valid) => {
          if (valid) {
            onClick(sizes);
          }
        })
        .catch((e) => {

          let errors = e.inner.reduce((acc, el) => {
            return {...acc, [el.path]: el.message}
          }, {});

          let newErrors = {...formErrors, ...errors}
          setFormErrors(newErrors);
        });
    }


  };

  return (
    <form className="sizeForm">
      <div className="checkbox-wrapper">
        <label className="label">
          <input
            type="checkbox"
            onChange={onCheckboxHandler}
            checked={
              sizes.gender === GENDER_TYPE.BOY
            }
            id="gender"
            name="Boy"
            className="checkbox"
          />
          <span className="fake"></span>
          <span className="text">Baby Boy</span>
        </label>
        <label className="label">
          <input
            type="checkbox"
            onChange={onCheckboxHandler}
            checked={
              sizes.gender === GENDER_TYPE.GIRL
            }
            id="gender"
            name="Girl"
            className="checkbox"
          />
          <span className="fake"></span>
          <span className="text">Baby Girl</span>
        </label>
        <div className="input-error">{formErrors && formErrors.gender}</div>
      </div>
      <div className="input-wrapper">
        <div className="label-wrapper">
          <DatePicker className="input"
                      type="number"
                      isClearable
                      placeholderText="yyyy/mm/dd"
                      value={sizes.birthDate}

                      maxDate={addDays(new Date(), 0)}
                      renderCustomHeader={({
                                             date,
                                             changeYear,
                                             changeMonth
                                           }) => (
                        <div
                          style={{
                            margin: 10,
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <select
                            value={getYear(date)}
                            onChange={({target: {value}}) => changeYear(value)}
                          >
                            {years.map((option) => (
                              <option key={option} value={option}>
                                {option}
                              </option>
                            ))}
                          </select>

                          <select
                            value={months[getMonth(date)]}
                            onChange={({target: {value}}) =>
                              changeMonth(months.indexOf(value))
                            }
                          >
                            {months.map((option) => (
                              <option key={option} value={option}>
                                {option}
                              </option>
                            ))}
                          </select>


                        </div>
                      )}
                      selected={startDate}
                      onChange={(date) => {
                        let dateString = ''
                        if (date) {
                          const newDate = date // replace with the date from the DatePicker
                          const localDate = new Date(newDate.getTime() - newDate.getTimezoneOffset() * 60000); // convert to local time zone
                          dateString = localDate.toISOString().substring(0, 10);
                        }
                        setSizes(
                          {
                            ...sizes,
                            birthDate: dateString
                          })
                      }
                      }
          />
          <label className="label" style={customStyle}>
            {t('form.dateOfBirth')}
          </label>
          {(formErrors && formErrors.birthDate) && (<div className="input-error">{formErrors.birthDate}</div>)}
        </div>
        <div className="label-wrapper">
          <input
            className="input"
            onChange={(e) => setSizes({...sizes, height: e.target.value})}
            value={sizes.height}
            type="number"
            id="height"
            placeholder={t('form.heightValue')}
          />
          <label className="label" style={customStyle}>
            {t('form.height')}
          </label>
          {(formErrors && formErrors.height) && (<div className="input-error">{formErrors.height}</div>)}
        </div>
        <div className="label-wrapper">
          <input
            className="input"
            onChange={(e) => setSizes({...sizes, weight: e.target.value})}
            value={sizes.weight}
            type="number"
            id="weight"
            placeholder={t('form.weightValue')}
          />
          <label className="label" style={customStyle}>
            {t('form.weight')}
          </label>
          {(formErrors && formErrors.weight) && (<div className="input-error">{formErrors.weight}</div>)}
        </div>
        <button
          className="button"
          onClick={(e) => {
            onClickHandler(e);
          }}
        >
          {t('form.search')}
        </button>
      </div>
      {!(formErrors.gender || formErrors.weight || formErrors.height || formErrors.birthDate) && mainFilteredError?.response.data.message && (
        <div className="input-error">{mainFilteredError?.response.data.message}</div>
      )}
    </form>
  );
};


export default SizeForm;
