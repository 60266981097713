import { call, put } from "redux-saga/effects";
import { actions as productsActions } from "./slice";
import * as productsServices from "./services";

/**
 * Fetch parameters request/response handler
 */
export function* fetchProductsSaga() {
    try {
        const { data } = yield call(productsServices.getData);

        yield put(productsActions.fetchDataSuccess(data));
    } catch (err) {
        yield put(productsActions.fetchDataFailed(err));
    } finally {
        yield put(productsActions.fetchDataFulfilled())
    }
}
