import React, { useState } from "react";
import { useTranslation } from "react-i18next";

// components
import FilterTitle from "../filterTitle";
import SlideToggle from "react-slide-toggle";

// styles
import "./index.scss"

const FilterBrand = ({ items, title, data, filterKey, onClick }) => {
    const { t } = useTranslation();
    const [filteredItems, setFilteredItems] = useState(items.slice(0, 6));
    const [showAll, setShowAll] = useState(false);
    const [searchActive, setSearchActive] = useState(false);

    const onShowAllHandler = () => {
        setFilteredItems(!showAll ? items : items.slice(0, 6));
        setShowAll(!showAll);
    }

    const onSearchHandler = (event) => {
        const searchValue = event.target.value;
        if (searchValue !== "") {
            const filteredItems = items.filter((item) => item.name.toLowerCase().includes(searchValue.toLowerCase()));
            setFilteredItems(filteredItems);
            setSearchActive(true);

        } else {
            setFilteredItems(showAll ? items : items.slice(0, 6));
            setSearchActive(false);
        }
    }

    const onCheckboxHandler = (event) => {
        let name = event.target.name;
        let dataArray = data.length ? data.split(',') : [];

        let inArrayIndex = dataArray.findIndex(item => item === name)

        if (dataArray.length && inArrayIndex >= 0) {
            dataArray.splice(inArrayIndex, 1)
        } else {
            dataArray.push(name)
        }
        onClick({ [filterKey]: dataArray.join(',') })
    }

    return (
        <div className="filter-brand">
            <SlideToggle>
                {({ toggle, setCollapsibleElement, toggleState }) => (
                    <>
                        <FilterTitle title={title} toggle={toggle} toggleState={toggleState}/>
                        <div ref={setCollapsibleElement}>
                            <input className="filter-brand__search" type="text" placeholder={t('filter.searchBrand')}
                                   onChange={onSearchHandler}/>
                            {filteredItems.length > 0 ? <div className="filter__list">
                                {filteredItems.map((item, index) => (
                                    <div className="app-checkbox" key={index}>
                                        <input type="checkbox"
                                               id={item.name}
                                               name={item.id}
                                               onChange={(e) => onCheckboxHandler(e)}
                                               checked={!!(data.split(',').find(id => Number(id) === item.id))}
                                               className="app-checkbox__input"/>
                                        <label htmlFor={item.name} className="app-checkbox__label">{item.name}</label>
                                        {/*<p>{item.quantity}</p>*/}
                                    </div>
                                ))
                                }
                            </div> : (<div>Not Found</div>)}

                            {!searchActive && items.length > 6 &&
                                <div onClick={onShowAllHandler}
                                     className="filter-brand__show-all">
                                    {!showAll ? `${t('filter.showAll')}` : `${t('filter.showLess')}`}
                                </div>
                            }
                        </div>
                    </>
                )}
            </SlideToggle>
        </div>
    );
}

export default FilterBrand;
