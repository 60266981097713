import React from "react";

// styles
import "./index.scss"

const AboutUs = () => {
  return (
    <section className="about-us">
      <div className="app-container">
        <h1 className="about-us__title">About Us</h1>
        <p className="about-us__text">
          At Perfect Baby Fit, we understand the challenges that parents face when it comes to finding the
          right size clothes for their babies and toddlers. We were motivated to start this website after
          facing difficulties in finding the right size clothes for our own newborn son. We were taken by
          surprise when we found that even the clothes we had ordered in advance were too small for him and he
          had to wear hospital clothes when we took him home. This experience made us realize the importance
          of providing parents with a hassle-free way to find the perfect size clothes for their newborns.
        </p>
        <p className="about-us__text">
          Our mission at Perfect Baby Fit is to make it easy and convenient for busy parents to find the
          perfect size clothes for their babies and toddlers. By providing links to different baby clothes
          brands that offer sizes based on your child's weight, height, and age, we hope to save parents time
          and frustration that would otherwise be spent searching and comparing different size charts.
        </p>
        <p className="about-us__text">
          We are also committed to being environmentally friendly. By helping parents find the right size
          clothes for their children, we hope to reduce the number of clothes that are thrown away because
          they are the wrong size. This is an important step in promoting sustainability and protecting our
          planet.
        </p>
        <p className="about-us__text">
          Thank you for choosing Perfect Baby Fit. We are dedicated to helping parents find the perfect fit
          for their little ones, whether they are newborns, babies, or toddlers. If you have any questions or
          feedback, please don't hesitate to contact us.
        </p>
      </div>
    </section>
  );
}

export default AboutUs;
