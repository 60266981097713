import {useEffect, useState} from 'react';
import {Route, Routes} from "react-router-dom";

// layout
import Layout from "./components/Layout";

// components
import Main from "./pages/Main";
import CatalogPage from "./pages/CatalogPage";
import AboutUs from "./pages/AboutUs";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import CookiePolicy from "./pages/CookiePolicy";

// styles
import './app.scss';

function App() {
  const [isLoading, setLoading] = useState(true);

  function someRequest() { //Simulates a request; makes a "promise" that'll run for 2.5 seconds
    return new Promise(resolve => setTimeout(() => resolve(), 1000));
  }

  useEffect(() => {
    someRequest().then(() => {
      const loaderElement = document.querySelector(".loader-container");
      if (loaderElement) {
        loaderElement.remove();
        setLoading(!isLoading);
      }
    });
  });

  if (isLoading) {
    return null;
  }

  return (
    <>
      <Routes>
        <Route path="/" element={<Layout/>}>
          <Route index element={<Main/>}/>
          <Route path="/catalog" element={<CatalogPage/>}/>
          <Route path="/about-us" element={<AboutUs/>}/>
          <Route path="/privacy-policy" element={<PrivacyPolicy/>}/>
          <Route path="/cookie-policy" element={<CookiePolicy/>}/>
          <Route path="*" element={<Main/>}/>
        </Route>
      </Routes>
    </>
  );
}

export default App;
