import React from "react";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";

// styles
import "./index.scss"

const Footer = () => {
  const {t} = useTranslation();
  return (
    <div className="footer">
      <div className="app-container">
        <nav className="footer_menu">
          <ul className="list footer_menu-list">
            <li className="item footer_menu-item">
              <Link to="/about-us" className="footer_menu-link">{t('footer.about')}</Link>
            </li>
            <li className="item footer_menu-item">
              <Link to="/privacy-policy" className="footer_menu-link">{t('footer.privacy')}</Link>
            </li>
            <li className="item footer_menu-item">
              <Link to="/cookie-policy" className="footer_menu-link">{t('footer.cookie')}</Link>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
}

export default Footer;
