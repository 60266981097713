import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import flag from "../../assets/images/flag2x.png";
import Arrow from "../../iconsComponents/Arrow";

import {Popover} from "@material-ui/core";
import ArrowUp from "../../iconsComponents/ArrowUp";
import Select from 'react-select'

//store
import {actions as locationActions} from "../../store/modules/localization/location/slice";
import {selectLocationData} from "../../store/modules/localization/location/selectors";
import {actions as languageActions} from "../../store/modules/localization/language/slice";
import {selectLanguageData} from "../../store/modules/localization/language/selectors";
import {actions as currencyActions} from "../../store/modules/localization/currency/slice";
import {selectCurrencyData, selectCurrentCurrencyId} from "../../store/modules/localization/currency/selectors";

// locale
import {useTranslation} from 'react-i18next';

// styles
import "./index.scss"
const CountryChange = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const location = useSelector(selectLocationData);
  const language = useSelector(selectLanguageData);
  const currency = useSelector(selectCurrencyData);
  const currentCurrencyId = useSelector(selectCurrentCurrencyId);

  const [anchorEl, setAnchorEl] = useState(false);
  const [locationOpt, setLocationOpt] = useState([]);
  // let [newLang, setNewLang] = useState()
  // let [langId, setLangId] = useState(localStorage.getItem('i18nextLng') === "en" ? 1 : 2)
  const [country] = useState("Israel");
  let [selectedCurrency, setSelectedCurrency] = useState(null);

  useEffect(() => {
    if (!location) dispatch(locationActions.fetchDataTrigger());
    if (!language) dispatch(languageActions.fetchDataTrigger());
    if (!currency) dispatch(currencyActions.fetchDataTrigger());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let locationOptions = location?.map(country => ({value: country.name, label: country.name}));
    setLocationOpt((locationOptions));
  }, [location])


  useEffect(() => {
    if (currency && currency.length) {
      let defaultCurrencyId = 2;
      if (!currentCurrencyId) {
        dispatch(currencyActions.updateCurrentCurrency(defaultCurrencyId));
      } else {
        defaultCurrencyId = currentCurrencyId
      }

      let currentCurrency = currency.find(item => item.id === Number(defaultCurrencyId))
      setSelectedCurrency(currentCurrency)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currency])
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(false);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  // const onChangeLangHandler = (language) => {
  //     setLangId(language);
  //     if (language === 1) {
  //         newLang = "en";
  //     }
  //     if (language === 2) {
  //         newLang = "heb";
  //     }
  //     setNewLang(newLang)
  //     i18n.changeLanguage(newLang);
  //     // window.location.reload();
  // };

  const handleActiveCurrency = (data) => {
    dispatch(currencyActions.updateCurrentCurrency(data.id));
    setSelectedCurrency(data)
  }

  return (
    <>
      <div className="country" onClick={handleClick}>
        <img src={flag} alt="country" width={44} height={44}/>
        <div className="country-name">
          <div>{country},</div>
          {selectedCurrency && (<div>{selectedCurrency.name}</div>)}
        </div>
        {
          !open ?
            <Arrow width={7} height={4}/>
            :
            <ArrowUp width={7} height={4}/>
        }
      </div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <div className="country-wrapper">
          <div className="country-wrapper-location">
            <h3 className="country-wrapper-title">{t('localization.location')}</h3>
            <div className="box">
              {location &&
                <Select
                  options={locationOpt}
                  defaultValue={locationOpt?.[0]}
                  // onChange={handleCountry}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: "5px",
                    width: "256px",
                    colors: {
                      ...theme.colors,
                      primary25: '#F7F7F7',
                      primary: '#BAD0D3',
                    },
                  })}>
                  {/*{location.map(country =>*/}
                  {/*    <option key={country.id} value={country.name}>{country.name}</option>*/}
                  {/*)};*/}
                </Select>
              }
            </div>
          </div>

          {/*language change*/}

          {/*<div className="country-wrapper-location">*/}
          {/*    <h3 className="country-wrapper-title">{t('localization.language')}</h3>*/}
          {/*    <div className="buttons">*/}
          {/*        {language && language.map((lang) => (*/}
          {/*            <div key={lang.id}*/}
          {/*                 className={lang.id === langId ? "active-lang" : ""}*/}
          {/*                // onClick={() => handleActiveLanguage(lang.name)}*/}
          {/*                 onClick={() => onChangeLangHandler(lang.id)}>{lang.name}</div>*/}
          {/*        ))}*/}
          {/*    </div>*/}
          {/*</div>*/}
          <div>
            <h3 className="country-wrapper-title">{t('localization.currency')}</h3>
            <div className="buttons">
              {currentCurrencyId && currency && currency.length && currency.map(cur => (
                <div key={cur.id}
                     className={Number(currentCurrencyId) === cur.id ? "active-lang" : ""}
                     onClick={() =>handleActiveCurrency(cur)}
                >{cur.name}</div>
              ))}
            </div>
          </div>
        </div>
      </Popover>
    </>
  )
}

export default CountryChange;
