export const FILTERS_KEYS = {
    clothesCategories: "categories",
    colors: "colors",
    companies: "companies",
    condition: "condition",
    material: "materials",
    shipping: "shipping",
    stock: "stock",
    priceFrom: "priceFrom",
    priceTo: "priceTo",
};
