//core
import React, {useEffect, useState} from "react";

//styles
import "./productCard.scss"

//components
import SlideToggle from "react-slide-toggle";
import {useMediaQuery} from "@material-ui/core";
import ProductContent from "../productContent/ProductContent";

const ProductCard = ({card, toggleModal, size, link}) => {
  const [isCollapsed, setCollapsed] = useState(false)
  const isMobile = useMediaQuery(`(max-width: 760px)`);

  useEffect(() => {
    if (isMobile) {
      setCollapsed(!isCollapsed)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobile])
  return (
    <div className="card-wrapper">
      {!isCollapsed ?
        <SlideToggle collapsed>
          {({toggle, setCollapsibleElement}) => (
            <ProductContent card={card} size={size} toggleModal={toggleModal} link={link}
                            setCollapsibleElement={setCollapsibleElement} toggle={toggle}/>
          )}
        </SlideToggle>
        :
        <ProductContent card={card} size={size} toggleModal={toggleModal} link={link}/>
      }
    </div>
  );
}

export default ProductCard;
