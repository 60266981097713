import React from "react";

// styles
import './index.scss'

const FilterSimple = ({collection, data, onClick}) => {
  const onCheckboxHandler = (event, key) => {
    onClick({[key]: data[key] !== event.target.name ? event.target.name : ''})
  }

  return (
    <div className="filter-simple">
      {collection.stock.map((item) => (
        <div className="app-checkbox" key={item.id}>
          <input type="checkbox" id={item.name}
                 name={item.id}
                 className="app-checkbox__input"
                 onChange={(e) => onCheckboxHandler(e, 'stock')}
                 checked={item.id == data.stock}
          />
          <label htmlFor={item.name} className="app-checkbox__label">{item.name}</label>
          {/*<p>{item.quantity}</p>*/}
        </div>
      ))
      }
      {collection.shipping.map((item) => (
        <div className="app-checkbox" key={item.id}>
          <input type="checkbox" id={item.name}
                 name={item.id}
                 className="app-checkbox__input"
                 onChange={(e) => onCheckboxHandler(e, 'shipping')}
                 checked={item.id == data.shipping}
          />
          <label htmlFor={item.name} className="app-checkbox__label">{item.name}</label>
          {/*<p>{item.quantity}</p>*/}
        </div>
      ))
      }
    </div>
  );
}

export default FilterSimple;
