// core
import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import ClickAwayListener from "react-click-away-listener";
import {useTranslation} from "react-i18next";

// store
import {actions as materialsActions} from "../../store/modules/filters/materials/slice";
import {selectMaterialsData} from "../../store/modules/filters/materials/selectors";
import {actions as colorsActions} from "../../store/modules/filters/colors/slice";
import {selectColorsData} from "../../store/modules/filters/colors/selectors";
import {actions as categoriesActions} from "../../store/modules/filters/categories/slice";
import {selectCategoriesData} from "../../store/modules/filters/categories/selectors";
import {actions as conditionActions} from "../../store/modules/filters/condition/slice";
import {selectConditionData} from "../../store/modules/filters/condition/selectors";
import {actions as companiesActions} from "../../store/modules/filters/companies/slice";
import {selectCompaniesData} from "../../store/modules/filters/companies/selectors";
import {actions as stockActions} from "../../store/modules/filters/stock/slice";
import {selectStockData} from "../../store/modules/filters/stock/selectors";
import {actions as shippingActions} from "../../store/modules/filters/shipping/slice";
import {selectShippingData} from "../../store/modules/filters/shipping/selectors";

// components
import Filter from "../Filter";
import PriceFilter from "../PriceFilter";
import ChooseFilter from "../ChooseFilter";
import FilterSimple from "../FilterSimple";
import FilterBrand from "../FilterBrand";

// styles
import "./index.scss";

const CatalogFilter = ({style, menuOpen, setMenuOpen, filters, onClick, width}) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const materials = useSelector(selectMaterialsData);
  const colors = useSelector(selectColorsData);
  const categories = useSelector(selectCategoriesData);
  const condition = useSelector(selectConditionData);
  const companies = useSelector(selectCompaniesData);
  const stock = useSelector(selectStockData);
  const shipping = useSelector(selectShippingData);

  useEffect(() => {
    if (!materials) dispatch(materialsActions.fetchDataTrigger());
    if (!colors) dispatch(colorsActions.fetchDataTrigger());
    if (!categories) dispatch(categoriesActions.fetchDataTrigger());
    if (!condition) dispatch(conditionActions.fetchDataTrigger());
    if (!companies) dispatch(companiesActions.fetchDataTrigger());
    if (!stock) dispatch(stockActions.fetchDataTrigger());
    if (!shipping) dispatch(shippingActions.fetchDataTrigger());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ClickAwayListener
      onClickAway={() => {
        setMenuOpen(width);
      }}
    >
      <form
        className={"catalog-filter " + (menuOpen && "catalog-filter--active")}
        style={style}
      >
        {stock && shipping && companies && categories && colors && condition && materials && (
          <ChooseFilter
            filters={filters}
            collection={{
              stock, shipping, companies, categories, colors, condition, materials,
            }}
            onClick={onClick}
          />
        )}
        {stock && shipping && (
          <FilterSimple
            collection={{stock, shipping}}
            data={{
              shipping: filters.shipping,
              stock: filters.stock,
            }}
            onClick={onClick}
          />
        )}
        <PriceFilter
          data={{
            priceFrom: filters.priceFrom,
            priceTo: filters.priceTo,
          }}
          onClick={onClick}
        />
        {companies && (
          <FilterBrand
            items={companies}
            title={t('filter.title.brand')}
            data={filters.companies}
            onClick={onClick}
            filterKey={"companies"}
          />
        )}
        {categories && (
          <Filter
            items={categories}
            title={t('filter.title.productType')}
            data={filters.clothesCategories}
            filterKey={"clothesCategories"}
            onClick={onClick}
          />
        )}
        {colors && (
          <Filter
            items={colors}
            title={t('filter.title.color')}
            data={filters.colors}
            filterKey={"colors"}
            onClick={onClick}
          />
        )}
        {condition && (
          <Filter
            items={condition}
            title={t('filter.title.condition')}
            data={filters.condition}
            filterKey={"condition"}
            onClick={onClick}
          />
        )}
        {materials && (
          <Filter
            items={materials}
            title={t('filter.title.material')}
            data={filters.material}
            filterKey={"material"}
            onClick={onClick}
          />
        )}
      </form>
    </ClickAwayListener>
  );
};

export default CatalogFilter;
