import { call, put } from "redux-saga/effects";
import { actions as languageActions } from "./slice";
import * as languageServices from "./services";

/**
 * Fetch parameters request/response handler
 */
export function* fetchLanguageSaga() {
    try {
        const { data } = yield call(languageServices.getData);

        yield put(languageActions.fetchDataSuccess(data));
    } catch (err) {
        yield put(languageActions.fetchDataFailed(err));
    } finally {
        yield put(languageActions.fetchDataFulfilled())
    }
}