import React from "react";

const SortIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M21 17L16.5 21.5L12 17" stroke="#53565A" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M16.4961 8.5V21.5" stroke="#53565A" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M12.5 7L8 2.5L3.5 7" stroke="#53565A" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M7.99609 15.5V2.5" stroke="#53565A" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>

    );
}

export default SortIcon;