import { call, put } from "redux-saga/effects";
import { actions as locationActions } from "./slice";
import * as locationServices from "./services";

/**
 * Fetch parameters request/response handler
 */
export function* fetchLocationSaga() {
    try {
        const { data } = yield call(locationServices.getData);

        yield put(locationActions.fetchDataSuccess(data));
    } catch (err) {
        yield put(locationActions.fetchDataFailed(err));
    } finally {
        yield put(locationActions.fetchDataFulfilled())
    }
}
