//core
import React from "react";
import { useTranslation } from "react-i18next";

//styles
import "./productContent.scss"

//components
import shopName from "../../assets/images/shop-name.png"

const ProductContent = ({ card, toggleModal, size, link, toggle, setCollapsibleElement }) => {
    const { t } = useTranslation();

    const handleModalOpen = () => {
        console.log(card.itemWebUrl)
        size(card.size)
        link(card.itemWebUrl)
        toggleModal()
    }

    return (
        <div className="card-wrapper-card" onMouseEnter={toggle} onMouseLeave={toggle}>
            <div
                className={card.stock === "InStock" ? "card-wrapper-content" : "card-wrapper-content not-active"}>
                <img src={shopName} alt="shop-name" className="card-shop-name"/>
                <div className="card_image-wrapper">
                    <img src={card.imageUrl} alt="product"/>
                </div>
                <a href={card.itemWebUrl} className="card-title">{card.title} </a>
                <div className="card-content">
                    <p className="card-price">{card.price}</p>
                    <p className="card-shipping">{card.shipping}</p>
                </div>
            </div>
            <div className="card-size-content">
                <div className="card-size-content-wrapper">
                    <div>
                        <span className="card-size-title">{t('products.yourSize')}:</span>
                        <span className="card-size">{card.size}</span>
                    </div>
                    {
                        card.stock === "InStock"
                            ? null
                            : <p className="card-size-out">Out of stock</p>
                    }
                </div>
                <div ref={setCollapsibleElement} className="card-button-wrapper">
                    {
                        card.stock === "InStock"
                            ?
                            <a href={card.itemWebUrl} target="_blank"
                               className="card-link" rel="noreferrer">{t('products.buy')}</a>
                            :
                            <button className="card-button"
                                    onClick={handleModalOpen}>{t('products.notify')}</button>
                    }
                </div>
            </div>
        </div>
    );
}

export default ProductContent;