import { createSelector } from "@reduxjs/toolkit";
import { initialState } from "./state";

const selectDomain = (state) => state.shipping || initialState;

export const selectShippingData = createSelector(
    [selectDomain],
    (shippingState) => shippingState.data
);

export const selectShippingLoading = createSelector(
    [selectDomain],
    (shippingState) => shippingState.fetch.loading
);

export const selectShippingDone = createSelector(
    [selectDomain],
    (shippingState) => shippingState.fetch.done
);

export const selectShippingError = createSelector(
    [selectDomain],
    (shippingState) => shippingState.fetch.error
);
