// core
import React, {useEffect, useRef, useState} from "react";
import {useLocation, useNavigate, useSearchParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {objectToString} from "../../utils";
import {FILTERS_TEMPLATE} from "../../utils/filters";
import ClipLoader from "react-spinners/ClipLoader";
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// store
import {actions as mainFilteredProductsActions} from "../../store/modules/mainFilteredProducts/slice";
import {
  selectMainFilteredData, selectMainFilteredError,
  selectMainFilteredLoading,
} from "../../store/modules/mainFilteredProducts/selectors";
import {selectCurrentCurrencyId} from "../../store/modules/localization/currency/selectors";

// components
import CatalogList from "../../components/CatalogList";
import EnteredSize from "./EnteredSize";
import CatalogFilter from "../../components/CatalogFilter";
import Pagination from "../../components/Pagination";

// style
import "./index.scss";

// data
import {getInitParams} from "./data";
import {useMediaQuery} from "@material-ui/core";
import SortProducts from "../../components/sortProducts/SortProducts";
import {useTranslation} from "react-i18next";

const CatalogPage = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(`(max-width: 760px)`);
  const [searchParams] = useSearchParams();

  const [params, setParams] = useState(getInitParams(location.search));
  const [enteredSize, setEnteredSize] = useState(null);
  const [advancedFilters, setAdvancedFilters] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const pageNumber = searchParams.get("pageNumber" || 1);

  const [width, setWidth] = useState(0);
  const [menuOpen, setMenuOpen] = useState(true);
  const ref = useRef(null);

  const mainFiltered = useSelector(selectMainFilteredData);
  const mainFilteredLoading = useSelector(selectMainFilteredLoading);
  const mainFilteredError = useSelector(selectMainFilteredError)
  const currentCurrencyId = useSelector(selectCurrentCurrencyId)
  const updateUrlParams = (data) => {
    const {pageSize, currency, ...clearedParams} = data;
    navigate(
      {
        pathname: "/catalog",
        search: `?${objectToString(clearedParams)}`,
      },
      {replace: true}
    );
  };


  //---init
  useEffect(() => {
    //---change url
    updateUrlParams(params);

    //---change filters data
    let enteredSizeKeys = ["gender", "birthDate", "height", "weight"];
    let enteredSizeObject = enteredSizeKeys.reduce((acc, key) => {
      let value =
        params.hasOwnProperty(key) && params[key]
          ? params[key]
          : FILTERS_TEMPLATE[key];
      return {...acc, [key]: value};
    }, {});
    setEnteredSize(enteredSizeObject);

    let advancedFilters = Object.keys(FILTERS_TEMPLATE).reduce((acc, key) => {
      let advancedFilter =
        enteredSizeKeys.indexOf(key) < 0
          ? {
            [key]:
              params.hasOwnProperty(key) && params[key]
                ? params[key]
                : FILTERS_TEMPLATE[key],
          }
          : {};
      return {...acc, ...advancedFilter};
    }, {});
    setAdvancedFilters(advancedFilters);

    console.log('advancedFilters',advancedFilters)
    console.log('enteredSizeObject',enteredSizeObject)
    console.log('params',params)

    dispatch(mainFilteredProductsActions.fetchDataTrigger({...params, ...enteredSizeObject}));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  const getSize = () => {
    if (width) {
      setWidth(ref.current.clientWidth);
    }
  };

  useEffect(() => {
    getSize();
    window.addEventListener("resize", getSize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const itemsPerPage = 8;
    if (mainFiltered?.totalItems) {
      setPageCount(Math.ceil(mainFiltered?.totalItems / itemsPerPage));
    }
  }, [pageCount, mainFiltered?.totalItems]);

  useEffect(() => {
    if (isMobile) {
      localStorage.setItem('mobile', "true");
    } else {
      localStorage.setItem('mobile', "false");
    }
  }, [isMobile])

  useEffect(() => {
    const {currency, ...otherParams} = params;
    if (currentCurrencyId && currentCurrencyId !== currency) {
      const editedParams = Object.assign({}, otherParams, {currency: currentCurrencyId})
      setParams(editedParams);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCurrencyId])

  const onPageHandler = (event) => {
    const selectedPage = event;
    const {pageNumber, ...clearedParams} = params;
    const newParams =
      selectedPage && selectedPage > 1
        ? Object.assign({}, clearedParams, {pageNumber: selectedPage})
        : clearedParams;
    setParams(newParams);
    window.scrollTo({top: 0});
  };

  const onFilterHandler = (filterParams) => {
    if (!mainFilteredLoading) {
      let editedParams = Object.assign({}, params);
      Object.keys(filterParams).forEach((key) => {
        if (filterParams[key]) {
          editedParams[key] = filterParams[key];
        } else if (editedParams.hasOwnProperty(key)) {
          delete editedParams[key];
        }
      });
      setParams(editedParams);
    } else {
      toast.warn("Wait, please!", {
        position: toast.POSITION.TOP_RIGHT
      });
    }
  };

  return (
    <div className="app-container">
      {enteredSize && (
        <EnteredSize enteredSize={enteredSize} onClick={onFilterHandler}/>
      )}
      <div className="catalog-page" ref={ref}>

        {(width >= 1000 || menuOpen) && advancedFilters && (
          <CatalogFilter
            width={window.innerWidth >= 1000}
            menuOpen={menuOpen}
            setMenuOpen={setMenuOpen}
            filters={advancedFilters}
            onClick={onFilterHandler}
            loading={mainFilteredLoading}
          />
        )}
        <div className="catalog_list">
          {mainFiltered?.totalItems > 1 &&
            <div className="catalog_list-header">
              <div className="catalog_list-results">
                <span>{t('products.result')}:</span>
                <span>{mainFiltered?.totalItems}</span>
                <span>/</span>
                <span>{t('products.page')} {pageNumber ? pageNumber : 1}</span>
              </div>
              <SortProducts params={params} setParams={setParams}/>
            </div>
          }
          <div
            className="catalog_list-button"
            onClick={() => setMenuOpen(!menuOpen)}
          >
            <p>{t('products.button')}</p>
          </div>
          {mainFilteredLoading ? (
            <div className="catalog-loader">
              <ClipLoader size={150} speedMultiplier={0.5} color="#0E2F4B"/>
            </div>
          ) : (mainFiltered?.totalItems > 0 ?
              <div>
                <CatalogList
                  mainFiltered={mainFiltered}
                  params={params}
                  setParams={setParams}
                />
                {mainFiltered?.totalItems > 8 &&
                  <Pagination
                    pageCount={pageCount}
                    onPage={onPageHandler}
                    initialPage={params.pageNumber ? params.pageNumber : 1}
                  />
                }
              </div>
              :
              <div className="catalog-no-results">
                {mainFilteredError?.response.data.message ?
                  mainFilteredError?.response.data.message
                  :
                  'No results'
                }
              </div>
          )}
        </div>
      </div>
      <ToastContainer
        position="bottom-right"
        theme="light"
      />
    </div>
  );
};

export default CatalogPage;
