import React from 'react';
import ReactDOM from 'react-dom/client';
import {BrowserRouter} from "react-router-dom";
import {Provider} from "react-redux";

// utils
import ScrollToTop from "./utils/scrollToTop";

// store
import store from './store'

// components
import App from './App';

// Initialize languages
import './locales/i18n';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <ScrollToTop/>
      <App/>
    </BrowserRouter>
  </Provider>
);

