import * as yup from "yup";

export const FILTERS_TEMPLATE = {
  gender: "",
  birthDate: "",
  height: "",
  weight: "",
  priceFrom: 0,
  priceTo: 10000,
  material: "",
  companies: "",
  colors: "",
  condition: "",
  clothesCategories: "",
  shipping: "",
  stock: "",
  pageNumber: "",
  sortings: "",
};

export const FormSchema = yup.object().shape({
  gender: yup.string().required("Please enter a value"),
  height: yup
    .number()
    .required("Please enter a value")
    .positive()
    .integer()
    .typeError("Please enter height in cm")
    .min("44", "Please, enter height from 44 to 96")
    .max("96", "Please, enter height from 44 to 96"),
  weight: yup
    .number()
    .required("Please enter a value")
    .positive()
    .integer()
    .typeError("Please enter weight in kg")
    .min("2", "Please, enter weight from 2 to 17")
    .max("17", "Please, enter weight from 2 to 17"),
  birthDate: yup
    .date()
    .typeError("Please enter a valid Date of birth")
    .required("Please enter a value")
    // .max("2023-00-00", "Please enter a valid Date of birth")
    .min("2000-00-00", "Date is too early"),
});

