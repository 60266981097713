// core

// component
import ReactPaginate from "react-paginate";

// styles
import "./index.scss";

const Pagination = ({ onPage, pageCount, initialPage }) => {
    const onPageChangeHandler = ({ selected }) => {
        onPage(selected + 1);
    };

    return (
        <div className="pagination">
            <ReactPaginate
                breakLabel="..."
                nextLabel=">"
                pageRangeDisplayed={1}
                marginPagesDisplayed={1}
                pageCount={pageCount}
                previousLabel="<"
                disabledClassName="disable-arrow"
                renderOnZeroPageCount={null}
                onPageChange={onPageChangeHandler}
                forcePage={initialPage - 1}
            />
        </div>
    );
};

export default Pagination;
