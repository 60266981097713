import React from "react";
import {Link} from "react-router-dom";

// components
import Logo from "../../iconsComponents/Logo";
import SmallLogo from "../../iconsComponents/SmallLogo";
import CountryChange from "../CountryChange";

// styles
import "./index.scss"

const Header = () => {
  return (
    <section className="header">
      <div className="app-container">
        <div className="header__container">
          <Link to="/" className="logo-link">
            <div className="logo">
              <SmallLogo/>
              <Logo className="logo-title"/>
            </div>
          </Link>
          <CountryChange/>
        </div>

      </div>
    </section>
  );
}

export default Header;
