import { call, put } from "redux-saga/effects";
import { actions as conditionActions } from "./slice";
import * as conditionServices from "./services";

/**
 * Fetch parameters request/response handler
 */
export function* fetchConditionSaga() {
    try {
        const {data} = yield call(conditionServices.getData);

        yield put(conditionActions.fetchDataSuccess(data));
    } catch (err) {
        yield put(conditionActions.fetchDataFailed(err));
    } finally {
        yield put(conditionActions.fetchDataFulfilled())
    }
}