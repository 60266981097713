//core
import React from "react";

// components
import Arrow from "../../iconsComponents/Arrow";
import ArrowUp from "../../iconsComponents/ArrowUp";

// styles
import "./index.scss"

const FilterTitle = ({title, toggle, toggleState}) => {

    return (
        <div className="catalog_filter-main-wrapper" onClick={toggle}>
            <h4 className="catalog_filter-title">{title}</h4>
            <div className="catalog_filter-title-arrow">
                {
                    toggleState === "COLLAPSED" && <Arrow width={8} height={4}/>
                }
                {
                    toggleState === "EXPANDED" && <ArrowUp width={8} height={4}/>
                }
            </div>
        </div>
    );
}

export default FilterTitle;
