import { call, put } from "redux-saga/effects";
import { actions as categoriesActions } from "./slice";
import * as categoriesServices from "./services";

/**
 * Fetch parameters request/response handler
 */
export function* fetchCategoriesSaga() {
    try {
        const { data } = yield call(categoriesServices.getData);

        yield put(categoriesActions.fetchDataSuccess(data));
    } catch (err) {
        yield put(categoriesActions.fetchDataFailed(err));
    } finally {
        yield put(categoriesActions.fetchDataFulfilled())
    }
}
